import React from 'react'
import { useUnit } from 'effector-react'

import { ContractDirection, ActWorkPIR } from '@/dal'
import { FirstTableCell, NewCheckbox, TableCell } from '@/ui'
import { formatDateString } from '@/lib/date'

import {
  $hiddenCellGroup,
  onChangePIRActWork,
  $isTree,
  sendActWorkVolumeFx,
} from '../../model/private'
import { FirstCellWrapper, TableRowWrapper, WorkStatusLabel } from '../parts'
import { $searchName } from '../../model/filters.private'
import { $isDraft } from '../../../model'

type Props = {
  item: ActWorkPIR
  isScrolled?: boolean
}

export const TableRowPIR = React.memo(({ item, isScrolled }: Props) => {
  const [isHiddenGroup, isTree, searchName, isDraft, isPending] = useUnit([
    $hiddenCellGroup,
    $isTree,
    $searchName,
    $isDraft,
    sendActWorkVolumeFx.pending,
  ])

  const {
    id,
    article,
    name,
    note,
    number_1c,
    estimate,
    period_cost,
    project,
    remainder,
    total_cost,
    unit,
    depth,
    start_date,
    end_date,
    status,
    is_completed,
  } = item

  const handleChange = (value: boolean) => {
    onChangePIRActWork({ workId: id, value })
  }

  return (
    <TableRowWrapper
      actType={ContractDirection.PDRD}
      hiddenFirstGroup={isHiddenGroup}
      isDraft={isDraft}
    >
      <FirstCellWrapper hasShadow={isScrolled}>
        <FirstTableCell
          code_1c={number_1c}
          depth={depth}
          hasChildren={false}
          isFolder={false}
          isExpanded={false}
          isTree={isTree}
          name={name}
          project={project}
          searchValue={searchName}
          hasRightDivider={isHiddenGroup}
        />
      </FirstCellWrapper>

      {!isHiddenGroup && (
        <>
          <TableCell isGray isText text={note} />
          <TableCell isGray isText text={article} />
        </>
      )}

      <TableCell value={estimate.current} decimalScale={4} />
      <TableCell value={remainder.current} decimalScale={4} />
      <TableCell text={formatDateString(start_date)} align="left" isText />
      <TableCell text={formatDateString(end_date)} align="left" isText />

      <TableCell>
        <NewCheckbox
          isChecked={is_completed.value}
          disabled={!is_completed.can_change || isPending}
          isBoolean
          onChange={handleChange}
        />
      </TableCell>

      <TableCell align="left">
        <WorkStatusLabel status={status} />
      </TableCell>

      <TableCell text={unit} align="center" isText />
      <TableCell value={total_cost} align="right" />
      <TableCell value={period_cost} align="right" />
    </TableRowWrapper>
  )
})

import { DocumentAlias, DocumentSubType } from '@/dal'

const nameAliasMap = {
  [DocumentAlias.ACT]: 'Выполнение',
  [DocumentAlias.BUDGET]: 'Планирование',
  [DocumentAlias.CONTRACT]: 'Договор',
  [DocumentAlias.ESTIMATE]: 'Смета по договору',
  [DocumentAlias.KOR_DOC]: 'Предварительная спецификация',
  [DocumentAlias.PAYMENT]: 'Платеж',
  [DocumentAlias.ADDITIONAL_AGREEMENT]: 'Доп. соглашение',
  [DocumentAlias.COMPENSATION_DOCUMENT]: 'Компенсация',
}

const nameSubAliasMap = {
  [DocumentSubType.DOP]: 'Доп. соглашение',
}

export const getDocumentNameByType = (
  documentAlias: DocumentAlias,
  doc_type?: DocumentSubType | null,
): string | null => {
  let name = nameAliasMap[documentAlias] ?? null
  if (name && doc_type) {
    name = nameSubAliasMap[doc_type] ?? null
  }
  return name
}

const nameForActionAliasMap = {
  [DocumentAlias.ACT]: 'Выполнения',
  [DocumentAlias.BUDGET]: 'Планирования',
  [DocumentAlias.CONTRACT]: 'Договора',
  [DocumentAlias.ESTIMATE]: 'Сметы по договору',
  [DocumentAlias.KOR_DOC]: 'Предварительной спецификации',
  [DocumentAlias.PAYMENT]: 'Платежа',
  [DocumentAlias.ADDITIONAL_AGREEMENT]: 'Доп. соглашения',
}

const nameForDeleteSubAliasMap = {
  [DocumentSubType.DOP]: 'Доп. соглашения',
}

export const getDocumentNameForActionByType = (
  documentAlias: DocumentAlias,
  doc_type?: DocumentSubType | null,
) => {
  let name = nameForActionAliasMap[documentAlias] ?? null
  if (name && doc_type) {
    name = nameForDeleteSubAliasMap[doc_type] ?? null
  }
  return name
}

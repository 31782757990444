import { FlattenSimpleInterpolation } from 'styled-components'

import { PaletteColor, palette } from './palette'
import { NotificationType, TextSize } from './types'
import {
  TextL, TextLLight, TextM, TextMLight, TextS, TextSLight,
} from './typography'

export const HEADER_HEIGHT = 72
export const VERTICAL_CONTENT_WRAPPER_PADDING = 24
export const VERTICAL_OFFSET_FOR_CONTENT = HEADER_HEIGHT + (VERTICAL_CONTENT_WRAPPER_PADDING * 2)
export const AUTO_SCROLL_PARAMS = `calc(100dvh - ${VERTICAL_OFFSET_FOR_CONTENT}px)`

export const DOCUMENT_STATUS_COLOR_MAP: {[key in number]: keyof typeof palette} = {
  1: 'grey70',
  2: 'accent100',
  3: 'accent100',
  4: 'accent100',
  5: 'grey70',
  7: 'red100',
  9: 'grey70',
  13: 'violet100',
  15: 'green',
  16: 'lightBlue',
  18: 'grey70',
  19: 'grey70',
}

export const NOTIFICATION_BG_COLOR: { [key in NotificationType]: PaletteColor } = {
  [NotificationType.Alert]: 'red10',
  [NotificationType.Info]: 'grey10',
  [NotificationType.Success]: 'lightGreen10',
  [NotificationType.Warning]: 'yellow20',
}

export const NOTIFICATION_ICON_COLOR: { [key in NotificationType]: PaletteColor } = {
  [NotificationType.Alert]: 'red100',
  [NotificationType.Info]: 'lightBlue',
  [NotificationType.Success]: 'green',
  [NotificationType.Warning]: 'accent80',
}

export const TEXT_SIZE_MAP: { [key in TextSize]: FlattenSimpleInterpolation } = {
  L: TextL,
  M: TextM,
  S: TextS,
  LL: TextLLight,
  ML: TextMLight,
  SL: TextSLight,
}

export const ACCEPTABLE_FILES = {
  // https://react-dropzone.js.org/#section-accepting-specific-file-types
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'application/xml': ['.xls'],
  'image/png': ['.png'],
  'image/jpeg': ['.jpeg', '.jpg'],
}

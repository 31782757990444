import { sample, split } from 'effector'

import { DocumentAlias } from '@/dal'

import { pushNavigate } from '@/features/app/model'
import { getRouterPathByDocumentAlias } from '@/lib/documentResolvers'
import { createToast } from '@/features/toast-service/model'
import {
  $action,
  $isDeletingDocuments,
  $isCopyingDocuments,
  $targetDocument,
  copyActFx,
  copyDocumentFx,
  copyPaymentFx,
  copyPlanFx,
  deleteActFx,
  deleteDocumentFx,
  deleteEstimateCorrectionFx,
  deletePaymentFx,
  deletePlanFx,
  onConfirm,
  onRejectConfirm,
  resetStores,
  $createDocumentInfo,
  closeCreateDocumentModal,
  copyEstimateFx,
  $isModalOpen,
  $selectedDocumentType,
  $contractInfo,
  selectType,
  $isModalWithSelect,
} from './private'
import {
  copyDocument,
  deleteDocument,
  onDocumentDeleted,
  forceDeleteDocById,
  onActDeleted,
  onKorDocDeleted,
  onPlanDeleted,
  onPaymentDeleted,
  onCreatedDocument,
  onActCreated,
  onKorDocCreated,
  onPlanCreated,
  onPaymentCreated,
  openCreateDocumentModal,
  openCreateDocumentWithSelect,
  setCreateDocumentInfo,
} from './public'
import { DocumentManagerActions, TargetDocument } from './types'

import './create-act.init'
import './create-plan.init'
import './create-kor-doc.init'
import './create-payment.init'

$isModalOpen
  .on([openCreateDocumentModal, openCreateDocumentWithSelect], () => true)
  .reset(closeCreateDocumentModal)

$isModalWithSelect
  .on(openCreateDocumentWithSelect, () => true)
  .reset(closeCreateDocumentModal)

$selectedDocumentType
  .on(selectType, (_, type) => type)
  .reset(closeCreateDocumentModal)

$targetDocument
  .on([deleteDocument, copyDocument], (_, target) => target)
  .reset(resetStores)

$createDocumentInfo
  .on(setCreateDocumentInfo, (_, info) => info)
  .reset(closeCreateDocumentModal)

$contractInfo
  .on(openCreateDocumentWithSelect, (_, contract) => contract)
  .reset(closeCreateDocumentModal, resetStores)

$action
  .on(deleteDocument, () => DocumentManagerActions.Delete)
  .on(copyDocument, () => DocumentManagerActions.Copy)
  .reset(resetStores)

$isDeletingDocuments
  .on(deleteDocumentFx, (arr, params) => [
    ...arr,
    {
      id: params.id,
      type: params.type,
    },
  ])
  .on(
    deleteDocumentFx.finally,
    (arr, { params }) => arr.filter((item) => item.id !== params.id && item.type !== params.type),
  )

$isCopyingDocuments
  .on(copyDocumentFx, (arr, params) => [...arr, { id: params.id, type: params.type }])
  .on(
    copyDocumentFx.finally,
    (arr, { params }) => arr.filter((item) => item.id !== params.id && item.type !== params.type),
  )

sample({
  clock: openCreateDocumentModal,
  fn: ({ type }) => type,
  target: selectType,
})

sample({
  clock: openCreateDocumentModal,
  fn: ({ type, ...payload }) => payload,
  target: setCreateDocumentInfo,
})

sample({
  clock: openCreateDocumentWithSelect,
  fn: (contract) => ({
    contractId: contract.id,
    contractName: contract.name,
    contractDirection: contract.direction,
    draft: contract.conflicting_draft_kor_doc,
    discardedDoc: contract.canceled_or_not_relevant_kor_doc,
  }),
  target: setCreateDocumentInfo,
})

sample({
  clock: [
    onRejectConfirm,
    deleteDocumentFx.done,
    copyDocumentFx.done,
  ],
  target: resetStores,
})

split({
  source: sample({
    clock: onConfirm,
    source: { target: $targetDocument, action: $action },
    filter: ({ target, action }) => Boolean(target && action),
    fn: ({ target, action }) => ({
      ...target,
      action,
    } as TargetDocument & {action: DocumentManagerActions}),
  }),
  match: {
    delete: ({ action }) => action === DocumentManagerActions.Delete,
    copy: ({ action }) => action === DocumentManagerActions.Copy,
  },
  cases: {
    delete: deleteDocumentFx,
    copy: copyDocumentFx,
  },
})

sample({
  clock: forceDeleteDocById,
  target: deleteDocumentFx,
})

sample({
  clock: deleteDocumentFx.done,
  fn: ({ params }) => ({
    id: params.id,
    type: params.type,
  }),
  target: onDocumentDeleted,
})

split({
  source: sample({
    clock: deleteDocumentFx.done,
    fn: ({ params }) => ({
      type: params.type,
      id: params.id,
    }),
  }),
  match: {
    onActDeleted: ({ type }) => type === DocumentAlias.ACT,
    onKorDocDeleted: ({ type }) => type === DocumentAlias.KOR_DOC,
    onPlanDeleted: ({ type }) => type === DocumentAlias.BUDGET,
    onPaymentDeleted: ({ type }) => type === DocumentAlias.PAYMENT,
  },
  cases: {
    onActDeleted,
    onKorDocDeleted,
    onPlanDeleted,
    onPaymentDeleted,
  },
})

createToast({
  effect: deleteDocumentFx,
  doneText: (text) => text,
})

sample({
  clock: copyDocumentFx.doneData,
  fn: ({ type, id }) => {
    const path = getRouterPathByDocumentAlias(type)
    return `${path}/${id}`
  },
  target: pushNavigate,
})

createToast({
  effect: copyDocumentFx,
  doneText: 'Копия создана',
})

sample({
  clock: onCreatedDocument,
  target: closeCreateDocumentModal,
})

split({
  source: onCreatedDocument,
  match: {
    onActCreated: ({ type }) => type === DocumentAlias.ACT,
    onKorDocCreated: ({ type }) => type === DocumentAlias.KOR_DOC,
    onPlanCreated: ({ type }) => type === DocumentAlias.BUDGET,
    onPaymentCreated: ({ type }) => type === DocumentAlias.PAYMENT,
  },
  cases: {
    onActCreated,
    onKorDocCreated,
    onPlanCreated,
    onPaymentCreated,
  },
})

sample({
  clock: onCreatedDocument,
  fn: ({ type, id }) => {
    const url = getRouterPathByDocumentAlias(type)
    return `${url}/${id}`
  },
  target: pushNavigate,
})

deleteDocumentFx.use(async ({ id, type, documentName }) => {
  switch (type) {
    case DocumentAlias.ACT: {
      await deleteActFx(id)
      return `${documentName} удалено`
    }
    case DocumentAlias.KOR_DOC: {
      await deleteEstimateCorrectionFx(id)
      return `${documentName} удалена`
    }
    case DocumentAlias.PAYMENT: {
      await deletePaymentFx(id)
      return `${documentName} удален`
    }
    case DocumentAlias.BUDGET: {
      await deletePlanFx(id)
      return `${documentName} удалено`
    }
    default: {
      throw new Error(`invalid document type. Type: ${type} unhandled`)
    }
  }
})

copyDocumentFx.use(async ({ id, type }) => {
  switch (type) {
    case DocumentAlias.ACT: {
      const response = await copyActFx(id)
      return {
        id: response.id,
        type: DocumentAlias.ACT,
      }
    }
    case DocumentAlias.KOR_DOC: {
      const response = await copyEstimateFx(id)
      return {
        id: response.document.id,
        type: response.document.type,
        deletedDoc: response.removed_draft_id,
      }
    }
    case DocumentAlias.PAYMENT: {
      const response = await copyPaymentFx(id)
      return {
        id: response.id,
        type: DocumentAlias.PAYMENT,
      }
    }
    case DocumentAlias.BUDGET: {
      const response = await copyPlanFx(id)
      return {
        id: response.id,
        type: DocumentAlias.BUDGET,
      }
    }
    default: {
      throw new Error(`invalid document type. Type: ${type} unhandled`)
    }
  }
})

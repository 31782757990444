import { useUnit } from 'effector-react'
import React from 'react'

import { DocumentInfoBlock } from '@/ui'

import {
  $contract,
  onOpenAdditionalModal,
} from '../../../model/private'

export const ContractInfoBlock = React.memo(() => {
  const contract = useUnit($contract)
  if (!contract) return null

  return (
    <DocumentInfoBlock
      contract={{
        id: contract.parent?.id,
        name: contract.parent?.name,
        project: contract.project,
      }}
      features={{
        can_create_visa: contract.features.can_create_visa,
        can_open_visa_chat: contract.features.can_open_visa_chat,
        is_answer_required: contract.features.is_answer_required,
      }}
      status={contract.status}
      progress={contract.responsible_progress}
      onOpenAdditional={onOpenAdditionalModal}
    />
  )
})

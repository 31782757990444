import { attach } from 'effector'

import { DocumentFilesInfo, GetDocumentFilesPayload, getDocumentFilesReqFx } from '@/dal'

import { d } from './domain'

export const $docFilesInfo = d.store<DocumentFilesInfo | null>(null)

export const $isDocFilesModalOpen = d.store(false)
export const openDocFilesModal = d.event()
export const closeDocFilesModal = d.event()

export const getDocumentFiles = d.event<GetDocumentFilesPayload>()

export const getDocumentFilesFx = attach({
  effect: getDocumentFilesReqFx,
})

import { SystemAlias } from '@/dal'
import { RouterPaths, Subsystem, SubSystemId } from './type'

export const NO_DATA = 'н/д'

export const INVALID_DATE = 'Invalid Date'

export const subSystem: { [key: number]: Subsystem } = {
  [SubSystemId.Home]: {
    link: RouterPaths.Home,
    subsystemId: SubSystemId.Home,
    alias: SystemAlias.Home,
    name: 'Главная',
  },
  [SubSystemId.Administrative]: {
    link: RouterPaths.Administrative,
    subsystemId: SubSystemId.Administrative,
    alias: SystemAlias.Administrative,
    name: 'Договоры',
  },
  [SubSystemId.DocumentFlow]: {
    link: RouterPaths.DocumentFlow,
    subsystemId: SubSystemId.DocumentFlow,
    alias: SystemAlias.DocumentFlow,
    name: 'Документооборот',
  },
  [SubSystemId.Help]: {
    link: RouterPaths.Help,
    subsystemId: SubSystemId.Help,
    alias: SystemAlias.Help,
    name: 'Помощь и поддержка',
  },
  [SubSystemId.CreateEstimate]: {
    link: null,
    subsystemId: SubSystemId.CreateEstimate,
    alias: SystemAlias.CreateEstimate,
    isInDevelopment: true,
    name: 'Создание сметы',
  },
  [SubSystemId.MyCompany]: {
    subsystemId: SubSystemId.MyCompany,
    name: 'Моя компания',
    link: RouterPaths.MyCompany,
    childLinks: [
      {
        link: RouterPaths.MyCompany,
        subsystemId: SubSystemId.MyCompany,
        name: 'Реквизиты компаний',
      },
      {
        link: RouterPaths.Contacts,
        subsystemId: SubSystemId.MyCompany,
        name: 'Справочник контактов',
      },
    ],
  },
  [SubSystemId.ActDoc]: {
    subsystemId: SubSystemId.ActDoc,
    alias: SystemAlias.ActConstructor,
    link: null,
  },
  [SubSystemId.EstimateCorrectionDoc]: {
    subsystemId: SubSystemId.EstimateCorrectionDoc,
    alias: SystemAlias.EstimateCorrection,
    link: null,
  },
  [SubSystemId.PaymentDoc]: {
    subsystemId: SubSystemId.PaymentDoc,
    link: null,
    alias: SystemAlias.Payments,
  },
  [SubSystemId.PlanDoc]: {
    subsystemId: SubSystemId.PlanDoc,
    link: null,
    alias: SystemAlias.FundingSchedule,
  },
  [SubSystemId.Compensation]: {
    subsystemId: SubSystemId.Compensation,
    link: null,
    alias: SystemAlias.Compensation,
  },
}

export const CONTRACT_SUBSYSTEMS = [
  SubSystemId.Compensation,
  SubSystemId.PlanDoc,
  SubSystemId.PaymentDoc,
  SubSystemId.EstimateCorrectionDoc,
  SubSystemId.ActDoc,
  SubSystemId.CreateEstimate,
]

export const DEFAULT_FILE_NAME = 'attachments'

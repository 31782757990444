import React from 'react'
import styled, { css } from 'styled-components'

import { textHighlight } from '@/lib/text-highlight'

import { TABLE_BORDER_STYLE } from './const'
import { FolderComponent } from './FolderComponent'
import { OverflowText } from '../OverflowText'
import { TextMLight } from '../../typography'
import { palette } from '../../palette'

const DEFAULT_ROW_PADDING = 38
const DEFAULT_FOLDER_PADDING = 20
const ROW_START_PADDING = 62
const STEP = 6

type Props = {
  isTree: boolean
  isFolder: boolean
  depth: number
  hasChildren: boolean
  isExpanded: boolean
  name: string
  code_1c: string
  project?: string
  searchValue?: string
  hasRightDivider?: boolean
}

export const FirstTableCell = ({
  isFolder,
  isTree,
  depth,
  hasChildren,
  isExpanded,
  name,
  code_1c,
  project,
  searchValue,
  hasRightDivider,
}: Props) => {
  const leftPadding = React.useMemo(() => {
    if (!isTree) return DEFAULT_ROW_PADDING
    let result = isFolder
      ? DEFAULT_FOLDER_PADDING : depth ? ROW_START_PADDING : DEFAULT_ROW_PADDING
    result += depth * STEP
    return result
  }, [isFolder, isTree, depth])

  const highlightedName = searchValue
    ? textHighlight(
      name,
      searchValue,
      palette.yellow30,
      'inherit',
    )
    : name

  const highlightedProject = project && searchValue
    ? textHighlight(
      project,
      searchValue,
      palette.yellow30,
      'inherit',
    )
    : project

  return (
    <Wrapper leftPadding={leftPadding} hasRightDivider={hasRightDivider}>
      <FolderComponent
        hasChildren={hasChildren}
        isExpanded={isExpanded}
        isFolder={isFolder}
      />
      <Info>
        <OverflowText
          maxTooltipWidth={350}
          maxLines={2}
        >
          {highlightedName}
        </OverflowText>

        <GreyText>
          {highlightedProject && (
            <Project>
              <OverflowText
                maxTooltipWidth={250}
                maxLines={1}
              >
                {highlightedProject}
              </OverflowText>
            </Project>
          )}

          {code_1c}
        </GreyText>

      </Info>
    </Wrapper>
  )
}

type WrapperProps = {
  leftPadding: number
  hasRightDivider?: boolean
}

const Wrapper = styled.div<WrapperProps>`
  padding: 12px 20px 12px 38px;
  display: flex;
  gap: 10px;
  border-right: ${TABLE_BORDER_STYLE};
  height: 100%;

  ${({ leftPadding }) => css`
    padding-left: ${leftPadding}px;
  `};

  ${({ hasRightDivider }) => hasRightDivider && css`
    border-width: 4px;
  `}
`

const Info = styled.div`
  display: flex;
  gap: 2px;
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  ${TextMLight}
`

const GreyText = styled.div`
  ${TextMLight}
  color: ${palette.grey70};
`

const Project = styled.div`
  margin-bottom: 6px;
`

import React from 'react'
import { Switch, Route, HashRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { LoginPage } from './pages/login'
import { RouteItem } from './routes/types'
import { TopBar } from './features/header'
import { ForceAuth } from './pages/force-auth'
import { RouteWrapper } from './routes/RouteWrapper'
import { DocumentFlowPage } from './pages/document-flow'
import { AdministrativePage, ContractPage } from './pages/administrative'
import { PaymentPage } from './pages/payment'
import { ActPage } from './pages/acts'
import { PlanEditPage } from './pages/funding-schedule'
import { RouterPaths, SubSystemId } from './features/app/model'
import { MaintenancePage } from './pages/maintenance/view'
import { MainPage } from './pages/main'

import { ModalProvider } from './ModalProvider'
import { HelpPage } from './pages/help'
import { MyCompanyPage } from './pages/my-company/view'
import { SupportPage } from './pages/support'
import { CreateSupportAppealPage } from './pages/create-support-appeal/view'
import { AdministrativeAppealsPage } from './pages/administrative-appeals'
import { EstimateCorrectionPage } from './pages/estimate-correction'
import { RestoreAccessPage } from './pages/restore-access'
import { RequestForAccessPage } from './pages/request-for-access'
import { PageLayout } from './ui'
import { EditProfilePage } from './pages/edit-profile'
import { CompensationPage } from './pages/compensation'

export const privateRoutes: RouteItem[] = [
  {
    path: RouterPaths.Home,
    component: MainPage,
    subsystemId: SubSystemId.Home,
    isExact: true,
  },
  {
    path: `${RouterPaths.ActPage}/:id`,
    component: ActPage,
    subsystemId: SubSystemId.ActDoc,
    isExact: true,
  },
  {
    path: `${RouterPaths.Payment}/:id`,
    component: PaymentPage,
    subsystemId: SubSystemId.PaymentDoc,
    isExact: false,
  },
  {
    path: RouterPaths.DocumentFlow,
    component: DocumentFlowPage,
    subsystemId: SubSystemId.DocumentFlow,
    isExact: true,
  },
  {
    path: `${RouterPaths.PlanPage}/:id`,
    component: PlanEditPage,
    subsystemId: SubSystemId.PlanDoc,
    isExact: true,
  },
  {
    path: RouterPaths.Administrative,
    component: AdministrativePage,
    subsystemId: SubSystemId.Administrative,
    isExact: true,
  },
  {
    path: `${RouterPaths.Administrative}/:id`,
    component: ContractPage,
    subsystemId: SubSystemId.Administrative,
    isExact: true,
  },
  {
    path: RouterPaths.Help,
    component: HelpPage,
    subsystemId: SubSystemId.Help,
    isExact: true,
  },
  {
    path: RouterPaths.MyCompany,
    component: MyCompanyPage,
    subsystemId: SubSystemId.MyCompany,
    isExact: false,
  },
  {
    path: RouterPaths.Support,
    component: SupportPage,
    subsystemId: SubSystemId.Help,
    isExact: false,
  },
  {
    path: RouterPaths.CreateSupportAppeal,
    component: CreateSupportAppealPage,
    subsystemId: SubSystemId.Help,
    isExact: false,
  },
  {
    path: RouterPaths.AdministrationAppeals,
    component: AdministrativeAppealsPage,
    subsystemId: SubSystemId.Help,
    isExact: false,
  },
  {
    path: `${RouterPaths.EstimateCorrection}/:id`,
    component: EstimateCorrectionPage,
    subsystemId: SubSystemId.EstimateCorrectionDoc,
    isExact: false,
  },
  {
    path: `${RouterPaths.Compensation}/:id`,
    component: CompensationPage,
    subsystemId: SubSystemId.Compensation,
    isExact: false,
  },
  {
    path: RouterPaths.UserInfo,
    component: EditProfilePage,
    subsystemId: SubSystemId.EditUserInfo,
    isExact: true,
  },
]

const publicRoutes: RouteItem[] = [
  {
    path: RouterPaths.Login,
    component: LoginPage,
    isExact: true,
  },
  {
    path: RouterPaths.RestoreAccess,
    component: RestoreAccessPage,
    isExact: false,
  },
  {
    path: `${RouterPaths.ForceAuth}/:token`,
    component: ForceAuth,
    isForceAuth: true,
    isExact: true,
  },
  {
    path: RouterPaths.RequestForAccess,
    component: RequestForAccessPage,
    subsystemId: SubSystemId.EstimateCorrectionDoc,
    isExact: true,
  },
]

const SentryRoute = Sentry.withSentryRouting(Route)

export const Routes = () => (
  <HashRouter>
    <Switch>
      {/* Страница не зависит от статуса авторизации */}
      <SentryRoute key={RouterPaths.Maintenance} path={RouterPaths.Maintenance}>
        <MaintenancePage />
      </SentryRoute>
      {privateRoutes.map(({
        component: Component,
        path,
        subsystemId,
        isExact,
      }) => (
        <SentryRoute key={path} path={path} exact={isExact}>
          <RouteWrapper isPrivate subsystemId={subsystemId}>
            <PageLayout header={<TopBar />}>
              <Component />
            </PageLayout>
          </RouteWrapper>
        </SentryRoute>
      ))}

      {publicRoutes.map(({
        component: Component,
        path,
        isForceAuth,
        isExact,
      }) => (
        <SentryRoute key={path} path={path} exact={isExact}>
          <RouteWrapper isPrivate={false} isForceAuth={isForceAuth}>
            <Component />
          </RouteWrapper>
        </SentryRoute>
      ))}
    </Switch>
    <ModalProvider />
  </HashRouter>
)

import { useUnit } from 'effector-react'
import React from 'react'
import styled from 'styled-components'

import {
  HeadXL,
  ModalLayoutV2,
  NewButton,
  TextLLight,
} from '@/ui'
import { useOnChangeLocation } from '@/lib/useOnChangeLocation'
import { getDocumentNameForActionByType } from '@/lib/documentResolvers'
import { noop } from '@/lib/noop'
import { DocumentStatuses } from '@/dal'

import {
  $action,
  $targetDocument,
  deleteDocumentFx,
  onConfirm,
  onRejectConfirm,
} from '../../model/private'
import { DocumentManagerActions } from '../../model'

export const DeleteDocumentConfirm = React.memo(() => {
  const target = useUnit($targetDocument)
  const isPending = useUnit(deleteDocumentFx.pending)
  const action = useUnit($action)

  useOnChangeLocation(onRejectConfirm)

  if (!target || action !== DocumentManagerActions.Delete) return null

  const documentType = getDocumentNameForActionByType(target.type)
  const isDraft = target.status === DocumentStatuses.Draft

  return (
    <ModalLayoutV2
      closeModal={isPending ? noop : onRejectConfirm}
    >
      <Wrapper>
        <Header>
          <Title>
            Удаление
            {' '}
            {documentType.toLowerCase()}
          </Title>
        </Header>
        {target.documentName && (
          <Body>
            Вы действительно хотите удалить
            {' '}
            {isDraft && 'черновик документа '}
            {target.documentName}
            {' '}
            ?
          </Body>
        )}
        <ButtonRow>
          <NewButton
            label="Да, удалить"
            dataTestId="delete-document-button"
            onClick={onConfirm}
            isPending={isPending}
            isFill
          />
          <NewButton
            label="Отменить"
            dataTestId="cancel-document-delete"
            onClick={onRejectConfirm}
            buttonType="grey"
            isDisabled={isPending}
            isFill
          />
        </ButtonRow>
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  padding: 32px;
  width: 480px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
`

const Body = styled.div`
  ${TextLLight}
`

const Title = styled.div`
  ${HeadXL}
`

const ButtonRow = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 12px;
`

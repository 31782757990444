import React from 'react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'
import { useUnit } from 'effector-react'

import {
  Icon2,
  InputField,
  NewDateInput,
  NewDropDownField,
  NewIconButton,
  NumberInputField,
} from '@/ui'

import {
  $bankAccountOptions,
  $contragentOptions,
  checkINNFx,
  editPaymentForm,
  onCheckINN,
  $isInnNotFoundError,
  onCreateContragent,
} from '../../../model/edit-private'
import { GridSpan } from '../../../shared-components'
import { $contragentInfo, $isBlockChanges } from '../../../model/private'
import { ContragentRequestNotification } from '../parts'
import { openCreateBank } from '../../../model/create-bank-private'

export const ThirdPersonFields = React.memo(() => {
  // TODO оцифровка, актуализировать файл
  const { fields } = useForm(editPaymentForm)
  const contragentOptions = useUnit($contragentOptions)
  const bankOptions = useUnit($bankAccountOptions)
  const isCheckINNPending = useUnit(checkINNFx.pending)
  const isBlockChanges = useUnit($isBlockChanges)
  const contragentInfo = useUnit($contragentInfo)
  const hasInnError = useUnit($isInnNotFoundError)

  const {
    inn,
    contragent,
    current_account,
    outgoing_mail_date,
    outgoing_mail_number,
  } = fields

  const contragentActionOptions = React.useMemo(() => {
    if (!hasInnError) return null
    return [
      {
        id: 'add-contragent',
        label: 'Нет подходящего, добавить свой',
        onClick: onCreateContragent,
        icon: 'plusWithCircle' as const,
      },
    ]
  }, [onCreateContragent, hasInnError])

  const bankActionOptions = React.useMemo(() => {
    return [
      {
        id: 'add-bank-account',
        label: 'Нет подходящего, добавить свой',
        onClick: openCreateBank,
        icon: 'plusWithCircle' as const,
      },
    ]
  }, [openCreateBank])

  return (
    <>
      <GridSpan span={2}>
        <INNField>
          <NumberInputField
            dataTestId="INN-field"
            label="ИНН 3-го лица"
            required
            onChange={inn.onChange}
            value={inn.value}
            allowLeadingZeros
            placeholder="Заполните поле"
            maxSymbolCount={12}
            disabled={isBlockChanges}
            size="L"
            isInvalid={!inn.isValid}
          />
          <NewIconButton
            icon="arrowRight"
            dataTestId="check-inn"
            size={16}
            padding="12px"
            backgroundColor="accent10"
            color="accent100"
            hoverBackgroundColor="accent20"
            disabled={isCheckINNPending || isBlockChanges}
            onClick={() => onCheckINN()}
            disabledColor="accent100"
            borderRadius="4px"
          />
          {Boolean(contragentOptions && !hasInnError && !contragentInfo) && (
            <INNIconWrapper>
              <Icon2
                icon="checkboxMark"
                color="lightGreen100"
                size={16}
              />
            </INNIconWrapper>
          )}
          {Boolean((!contragentOptions && hasInnError) || contragentInfo) && (
            <INNIconWrapper>
              <Icon2
                icon="clock"
                color="accent100"
                tooltipText="Контрагента нет в системе"
                size={16}
              />
            </INNIconWrapper>
          )}
        </INNField>
      </GridSpan>

      <GridSpan span={2}>
        <NewDropDownField
          dataTestId="contractor-field"
          label="Контрагент (3-е лицо)"
          onOptionClick={contragent.onChange}
          selectedId={contragent.value}
          options={contragentOptions}
          placeholder="Выберите контрагента"
          isDisabled={(!contragentOptions && !contragentActionOptions) || isBlockChanges}
          defaultOptionText="Нет активных контрагентов"
          hasSearch
          actionOptions={contragentActionOptions}
          size="L"
          isInvalid={!contragent.isValid}
        />
      </GridSpan>

      {Boolean(contragentInfo) && (
        <GridSpan span={4}>
          <INNNotificaiton>
            <ContragentRequestNotification />
          </INNNotificaiton>
        </GridSpan>
      )}

      <GridSpan span={2}>
        <NewDropDownField
          dataTestId="current-account-field"
          label="Расчетный счет 3-го лица"
          placeholder="Выберите расчетный счет"
          onOptionClick={current_account.onChange}
          selectedId={current_account.value}
          options={bankOptions}
          actionOptions={bankActionOptions}
          isDisabled={!bankOptions || isBlockChanges}
          hasSearch
          size="L"
          isInvalid={!current_account.isValid}
        />
      </GridSpan>

      <InputField
        dataTestId="mail-number-field"
        label="№ Исходящ. письма"
        onChange={outgoing_mail_number.onChange}
        value={outgoing_mail_number.value}
        placeholder="Заполните поле"
        onClear={outgoing_mail_number.reset}
        size="L"
        disabled={isBlockChanges}
        isInvalid={!outgoing_mail_number.isValid}
      />
      <NewDateInput
        label="Дата исходящ. письма"
        dataTestId="date-mail-field"
        onChange={outgoing_mail_date.onChange}
        inputValue={outgoing_mail_date.value}
        size="L"
        disabled={isBlockChanges}
        isInvalid={!outgoing_mail_date.isValid}
      />
    </>
  )
})

const INNNotificaiton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: -8px;
`

const INNField = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  align-items: flex-start;
  gap: 8px;

  & button {
    margin-top: 27px;
  }
`

const INNIconWrapper = styled.div`
  position: absolute;
  right: 60px;
  bottom: 12px;
  display: flex;
  align-items: center;
`

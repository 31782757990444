import { attach } from 'effector'
import { createGate } from 'effector-react'
import { createForm } from 'effector-forms'

import { putCompanyDetailsReqFx } from '@/dal'
import { rules } from '@/lib/form-validators'

import { d } from './domain'

export const DetailsGate = createGate('my-company-details')

export const $isEditing = d.store<boolean>(false)
export const openEditing = d.event<void>()
export const closeEditing = d.event<void>()

export const credentialsForm = createForm({
  domain: d,
  validateOn: ['change', 'submit'],
  fields: {
    kpp: {
      init: '',
      rules: [
        rules.required(),
        rules.exactLength(9),
      ],
    },
    okpo: {
      init: '',
      rules: [
        rules.required(),
        rules.exactLength(8),
      ],
    },
    bank_bik: {
      init: '',
      rules: [
        rules.required(),
        rules.exactLength(9),
      ],
    },
    acc_number: {
      init: '',
      rules: [
        rules.required(),
        rules.exactLength(20),
      ],
    },
    phone_number: {
      init: '',
      rules: [
        rules.phoneNumber(),
      ],
    },
    sign_name: {
      init: '',
      rules: [rules.fullName()],
    },
    sign_position: {
      init: '',
    },
    legal_address: {
      init: '',
      rules: [rules.required()],
    },
  },
})

export const putCompanyDetailsFx = attach({
  effect: putCompanyDetailsReqFx,
})

import { combine } from 'effector'

import {
  ActDTO,
  ContractItem,
  EstimateDoc,
  PaymentInfo,
  Plan,
} from '@/dal'

import { d } from './domain'
import {
  CreateDocumentInfo,
  CreateDocumentPayload,
  OnCreatedDocumentPayload,
  TargetDocument,
} from './types'
import { createKorDocFx } from './create-kor-doc.private'
import { createPaymentFx } from './create-payment.private'
import { createActFx } from './create-act.private'
import { createPlanFx } from './create-plan.private'

export const deleteDocument = d.event<TargetDocument>()
export const copyDocument = d.event<TargetDocument>()
export const forceDeleteDocById = d.event<TargetDocument>()
export const onDocumentDeleted = d.event<Omit<TargetDocument, 'documentName'>>()

export const onActDeleted = d.event<{ id: TargetDocument['id'] }>()
export const onKorDocDeleted = d.event<{ id: TargetDocument['id'] }>()
export const onPlanDeleted = d.event<{ id: TargetDocument['id'] }>()
export const onPaymentDeleted = d.event<{ id: TargetDocument['id'] }>()

export const openCreateDocumentModal = d.event<CreateDocumentPayload>()
export const setCreateDocumentInfo = d.event<CreateDocumentInfo>()
export const openCreateDocumentWithSelect = d.event<ContractItem>()

export const onCreatedDocument = d.event<OnCreatedDocumentPayload>()
export const onActCreated = d.event<ActDTO>()
export const onKorDocCreated = d.event<EstimateDoc>()
export const onPlanCreated = d.event<Plan>()
export const onPaymentCreated = d.event<PaymentInfo>()

export const $isKorDocCreating = createKorDocFx.pending
export const $isActCreating = createActFx.pending
export const $isPlanCreating = createPlanFx.pending
export const $isPaymentCreating = createPaymentFx.pending

export const $isSomeDocumentCreating = combine(
  [$isKorDocCreating, $isActCreating, $isPlanCreating, $isPaymentCreating],
  (args) => args.includes(true),
)

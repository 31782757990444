import React from 'react'
import { useUnit } from 'effector-react'

import { DocumentHeader } from '@/ui'
import { RouterPaths } from '@/features/app/model'

import { $documentInfo, openApprovingModal } from '../../model/private'

export const CompensationHeader = React.memo(() => {
  const info = useUnit($documentInfo)

  if (!info) return null

  const showApproveButton = info.features.can_approve || info.features.can_send_to_approving
  const approveTitle = 'Согласовать'

  return (
    <DocumentHeader
      documentName={info.name}
      lastUpdate={info.last_update}
      link={RouterPaths.Administrative}
      approveTitle={approveTitle}
      showApproveButton={showApproveButton}
      docOnApproving={info.features.on_approving}
      downloadInstructionText="Как подписать документы?"
      onApproveButton={openApprovingModal}
    />
  )
})

import React, { KeyboardEvent } from 'react'
import styled, { css } from 'styled-components'
import NumberFormat from 'react-number-format'

import { palette } from '../palette'
import { DefaultInputProps, MaskInputProps } from './types'
import { InputStyle } from './styles'
import { NewInputWrapper } from './NewInputWrapper'

type Props =
  & Omit<DefaultInputProps, 'type'>
  & MaskInputProps
  & { letterSpacing?: string }

export const NumberInput = ({
  onChange,
  onClear,
  onPostfixIconClick,
  onEnterPress,
  error,
  size = 'M',
  dataTestId,
  value,
  isInvalid,
  placeholder,
  disabled,
  type = 'text',
  name,
  postfixIcon,
  maxLength,
  readOnly,
  isSelect = false,
  isSelectAfterClear = true,
  letterSpacing,
  mask,
  allowEmptyFormatting,
  format,
  decimalScale,
  allowLeadingZeros,
  decimalSeparator,
  fixedDecimalScale,
  allowedDecimalSeparators,
  allowNegative = false,
  thousandSeparator,
}: Props) => {
  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      onEnterPress?.()
    }
  }

  return (
    <NewInputWrapper
      onClear={onClear}
      onPostfixIconClick={onPostfixIconClick}
      value={value}
      size={size}
      error={error}
      isInvalid={Boolean(isInvalid)}
      disabled={Boolean(disabled)}
      readOnly={readOnly}
      postfixIcon={postfixIcon}
      isSelect={isSelect}
      isSelectAfterClear={isSelectAfterClear}
      dataTestId={dataTestId}
      isMaskInput
    >
      <Input
        data-testid={dataTestId}
        maxLength={maxLength}
        readOnly={readOnly}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        name={name}
        type={type}
        onKeyPress={handleKeyPress}
        onValueChange={(e) => onChange(e.value)}
        format={format}
        mask={mask}
        allowEmptyFormatting={allowEmptyFormatting}
        isValuePlaceholder={Boolean(allowEmptyFormatting) && !value}
        letterSpacing={letterSpacing}
        decimalScale={decimalScale}
        allowLeadingZeros={allowLeadingZeros}
        decimalSeparator={decimalSeparator}
        fixedDecimalScale={fixedDecimalScale}
        allowedDecimalSeparators={allowedDecimalSeparators}
        allowNegative={allowNegative}
        thousandSeparator={thousandSeparator}
      />
    </NewInputWrapper>
  )
}

const Input = styled(NumberFormat)<{isValuePlaceholder: boolean, letterSpacing?: string}>`
  ${InputStyle}

  ${({ isValuePlaceholder }) => isValuePlaceholder && css`
    color: ${palette.grey60};
  `}

  ${({ letterSpacing }) => letterSpacing && css`
    letter-spacing: ${letterSpacing};
  `}
`

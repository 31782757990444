import React from 'react'
import styled from 'styled-components'

import { Icon2 } from '../../icons/Icon2'

type Props = {
  isFolder: boolean
  isExpanded: boolean
  hasChildren: boolean
}

export const FolderComponent = ({
  isFolder,
  isExpanded,
  hasChildren,
}: Props) => {
  if (!isFolder) return null
  return (
    <FolderRow>
      <IconWrapper>
        {hasChildren && (
          <Icon2
            icon={isExpanded ? 'minusWithCircle' : 'plusWithCircle'}
            size={12}
            color={isExpanded ? 'accent100' : 'grey70'}
          />
        )}
      </IconWrapper>
      <FoldewIconWrapper>
        <Icon2
          icon={isExpanded ? 'activeFolder' : 'folder'}
          height={14}
          size={16}
        />
      </FoldewIconWrapper>
    </FolderRow>
  )
}

const IconWrapper = styled.div`
  min-width: 12px;
`

const FolderRow = styled.div`
  display: flex;
  gap: 2px;
  align-self: flex-start;
`

const FoldewIconWrapper = styled.div`
  padding: 0px 5px;
`

import React from 'react'
import styled from 'styled-components'

import { HeadL, HeadM, palette } from '@/ui'

type Props = {
  workCount: number | null
}

export const GroupTitle = ({ workCount }:Props) => {
  return (
    <Title>
      Список работ
      <WorkCount>
        {workCount}
      </WorkCount>
    </Title>
  )
}

const Title = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  ${HeadL}
`

const WorkCount = styled.span`
  ${HeadM}
  color: ${palette.grey60};
`

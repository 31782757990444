import React from 'react'
import styled, { css } from 'styled-components'
import { useGate, useUnit } from 'effector-react'
import { combine } from 'effector'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { TableVirtuosoHandle } from 'react-virtuoso'

import {
  LoaderOverlay,
  NewButton,
  ScrollTopButton,
  SimpleLoader,
  Switch,
  palette,
} from '@/ui'

import { createAttachmentsModalView } from '@/features/factories/attach-modal'
import { GroupTree } from '@/features/group-tree/view'
import { EstimateTableRow } from '@/dal'

import { TableFilter } from '../../table-filters'
import {
  $isTableLoading,
  $isTreeTable,
  TableGate,
  attachmentsModel,
  changeIsTree,
  paginateFlatTableFx,
  addCreatedItemsFx,
  $scrollToIndex,
  resetScrollToId,
  splitRowAttachmentsModel,
  $totalWorkCount,
  $isExitSpecialModeModalOpen,
  openExitSpecialModeModal,
} from '../../../model/private'
import {
  TreeTable, FlatTable, ConfirmExitModeModal, PendingOverlay,
} from '../containers'
import { CreateTableItem } from '../../create-table-item'
import { SplitVolumeModal } from '../../split-volume/entries'
import {
  $editTargetId, acceptChanges, cancelChanges, updateEstimateItemFx,
} from '../../../model/edit-table-item.private'
import { ResetConfirm } from '../../reset-table-item'
import { onResetTable } from '../../../model/reset-table-item.private'
import { DeleteItemConfirm } from '../../delete-table-item'
import { $documentFeatures, $documentId } from '../../../../shared-model'
import { EstimateItemCommentsModal } from '../../comments-item'
import { ActionsRow } from '../parts'
import { $createdTableItem } from '../../../model/create-table-item.private'
import { resetKorDocRowsFx } from '../../../model/reset-multiple-items.private'
import { MultipleEditControls } from '../../table-filters/container'
import { $isSpecialMode } from '../../../model'
import { $isMultipleEditModeEnabled } from '../../../model/edit-multiple-items.private'

const AttacmentsModal = createAttachmentsModalView(attachmentsModel)
const SplitRowAttachmentsModal = createAttachmentsModalView(splitRowAttachmentsModel)

const $showTableLoader = combine(
  paginateFlatTableFx.pending,
  addCreatedItemsFx.pending,
  updateEstimateItemFx.pending,
  $createdTableItem,
  (...args) => args.some((val) => val),
)

type Props = {
  scrollRef: React.RefObject<Scrollbars>
}

const $canReset = $documentFeatures.map((features) => Boolean(features?.can_reset))

export const EstimateTable = React.memo(({ scrollRef }: Props) => {
  const contrainerRef = React.useRef<HTMLDivElement>(null)

  const isTree = useUnit($isTreeTable)
  const isPending = useUnit($isTableLoading)
  const docId = useUnit($documentId)
  const showTableLoader = useUnit($showTableLoader)
  const tableRef = React.useRef<TableVirtuosoHandle>(null)
  const tableWrapperRef = React.useRef<HTMLDivElement>(null)
  const index = useUnit($scrollToIndex)
  const canReset = useUnit($canReset)
  const editTargetId = useUnit($editTargetId)
  const workCount = useUnit($totalWorkCount)
  const isUpdateItemPending = useUnit(updateEstimateItemFx.pending)
  const isMultipleEdit = useUnit($isMultipleEditModeEnabled)

  const isSpecialMode = useUnit($isSpecialMode)
  const isExitSpecialModeModalOpen = useUnit($isExitSpecialModeModalOpen)
  const isResettingPending = useUnit(resetKorDocRowsFx.pending)

  useGate(TableGate)

  const scrollTableToIndex = (index: number) => {
    setTimeout(() => {
      tableRef.current?.scrollToIndex({
        align: 'center',
        behavior: 'smooth',
        index,
      })
    }, 150)
  }

  React.useLayoutEffect(() => {
    if (
      index === null
      || !scrollRef.current
      || !tableRef.current
      || !tableWrapperRef.current
    ) return
    const rect = tableWrapperRef.current.getBoundingClientRect()
    scrollRef.current.scrollTop(rect.bottom)

    scrollTableToIndex(index)
    resetScrollToId()
  }, [index])

  if (!docId) return null

  const isEditing = Boolean(editTargetId && !isMultipleEdit)

  const handleScrollButtonClick = () => {
    scrollTableToIndex(0)
  }

  React.useEffect(() => {
    const handleClickOutside = (e) => {
      if (contrainerRef.current && !contrainerRef.current.contains(e.target)) {
        if (isSpecialMode && !isExitSpecialModeModalOpen && !isResettingPending) {
          openExitSpecialModeModal()
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isSpecialMode, isExitSpecialModeModalOpen, isResettingPending, contrainerRef])

  return (
    <Wrapper>
      <GroupTree workCount={workCount} />

      <Container ref={contrainerRef}>
        <Row>
          {isEditing ? (
            <ActionsRow
              onAccept={acceptChanges}
              onCancel={cancelChanges}
              rowId={editTargetId as EstimateTableRow['id']}
              isPending={isUpdateItemPending}
            />
          ) : (
            <>
              <Col>
                <Switch
                  isChecked={isTree}
                  label="Папки"
                  disabled={isSpecialMode}
                  onChange={() => changeIsTree(!isTree)}
                />
                <VerticalLine />
                <TableFilter />
              </Col>

              <Col>
                {canReset && !isSpecialMode && (
                  <NewButton
                    dataTestId="reset-table"
                    label="Сбросить изменения"
                    onClick={() => onResetTable()}
                    prefixIcon="refresh"
                    size="S"
                  />
                )}
                <MultipleEditControls />
              </Col>
            </>
          )}

        </Row>

        <TableWrapper ref={tableWrapperRef}>
          {isPending
            ? (
              <LoaderWrapper>
                <SimpleLoader />
              </LoaderWrapper>
            ) : (
              <>
                {isTree ? <TreeTable ref={tableRef} /> : <FlatTable ref={tableRef} />}
              </>
            )}

          <LoaderOverlay show={showTableLoader} />

        </TableWrapper>

        <PendingOverlay />
      </Container>

      <AttacmentsModal />
      <SplitRowAttachmentsModal />
      <CreateTableItem />
      <SplitVolumeModal />
      <ResetConfirm />
      <DeleteItemConfirm />
      <EstimateItemCommentsModal />
      <ConfirmExitModeModal />
      <ScrollTopButton
        scrollContainerRef={scrollRef}
        bgColor="grey100"
        iconColor="grey60"
        hoverIconColor="white"
        treshold={150}
        onClick={handleScrollButtonClick}
      />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Container = styled.div`
  position: relative;
  background-color: ${palette.white};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 90dvh;
`

const VerticalLine = styled.div`
  height: 32px;
  width: 1px;
  background-color: ${palette.grey50};
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 20px;
`

const Col = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const TableWrapper = styled.div`
  flex-grow: 1;
  position: relative;

  thead {
    z-index: 5 !important;
  }
`

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

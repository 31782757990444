import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'
import Scrollbars from 'react-custom-scrollbars-2'

import { DocumentFileStatus } from '@/dal'
import { noop } from '@/lib/noop'
import { SimpleLoader } from '@/ui/Loader'
import {
  HeadXL,
  Icon2,
  ModalLayoutV2,
  NewButton,
  NewTextButton,
  PaletteColor,
  TextM,
  TextMLight,
} from '@/ui'

import {
  $docFilesInfo,
  $isDocFilesModalOpen,
  closeDocFilesModal,
  getDocumentFilesFx,
} from '../../model/private'

const STATUS_NAMES = {
  [DocumentFileStatus.Draft]: 'черновик',
  [DocumentFileStatus.InWork]: 'в обработке',
  [DocumentFileStatus.Signed]: 'подписан',
}

const STATUS_COLORS = {
  [DocumentFileStatus.Draft]: 'lightBlue' as PaletteColor,
  [DocumentFileStatus.InWork]: 'accent80' as PaletteColor,
  [DocumentFileStatus.Signed]: 'lightGreen100' as PaletteColor,
}

export const DocumentFilesModal = () => {
  const [isOpen, info, isPending] = useUnit([
    $isDocFilesModalOpen,
    $docFilesInfo,
    getDocumentFilesFx.pending,
  ])

  if (!isOpen) return null

  const allDocsUrl = info?.download_in_work_files_url
  const signedDocsUrl = info?.download_signed_files_url

  return (
    <ModalLayoutV2 closeModal={closeDocFilesModal}>
      <Wrapper>
        <Header>Документы</Header>

        {isPending ? (
          <LoaderWrapper>
            <SimpleLoader />
          </LoaderWrapper>
        ) : (
          <>
            <Scrollbars autoHeight autoHeightMin={0} autoHeightMax="60dvh">
              <DocumentList>
                <Row>
                  <HeaderLabel>Наименование документа</HeaderLabel>
                  <HeaderLabel>Статус документа</HeaderLabel>
                </Row>

                {info?.document_files.map((item) => (
                  <Row key={item.id}>
                    <NewTextButton
                      label={item.name}
                      url={item.url}
                      textStyle="ML"
                      dataTestId={`${item.url}-download-link`}
                      isDownload
                      hasUnderline
                    />
                    <StatusWrapper>
                      <Icon2
                        icon={item.status === DocumentFileStatus.Signed ? 'success' : 'info'}
                        color={STATUS_COLORS[item.status]}
                        size={16}
                      />
                      <span>{STATUS_NAMES[item.status]}</span>
                    </StatusWrapper>
                  </Row>
                ))}
              </DocumentList>
            </Scrollbars>

            <Buttons>
              <DownloadLink href={allDocsUrl} download>
                <NewButton
                  label="Скачать документы на согласование"
                  buttonType="line-primary"
                  isDisabled={!allDocsUrl}
                  dataTestId=""
                  isFill
                  onClick={noop}
                />
              </DownloadLink>

              {signedDocsUrl && (
                <DownloadLink href={signedDocsUrl} download>
                  <NewButton
                    label="Скачать подписанные документы"
                    dataTestId=""
                    isFill
                    onClick={noop}
                  />
                </DownloadLink>
              )}
            </Buttons>
          </>
        )}
      </Wrapper>
    </ModalLayoutV2>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 720px;
  padding: 32px;
`

const Header = styled.header`
  ${HeadXL}
`

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const DocumentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(0, 150px);
  gap: 8px;
`

const HeaderLabel = styled.p`
  ${TextM}
`

const StatusWrapper = styled.div`
  ${TextMLight}
  display: flex;
  align-items: center;
  gap: 4px;
`

const Buttons = styled.div`
  display: flex;
  gap: 8px;
`

const DownloadLink = styled.a`
  flex: 1 1 0;
`

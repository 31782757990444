import React from 'react'
import styled from 'styled-components'

import {
  ModalLayoutV2,
  NewButton,
  TextLLight,
  palette,
} from '@/ui'

type Props = {
  onCancel: VoidFunction
  onConfirm: VoidFunction
  isPending: boolean
  documentName: string
}

export const CancelConfirm = ({
  documentName,
  onCancel,
  onConfirm,
  isPending,
}: Props) => (
  <ModalLayoutV2
    closeModal={onCancel}
    dataTestId="cancel-appeal-modal"
  >
    <Wrapper>
      <Text>
        Хотите отменить
        {' '}
        {documentName}
        ? После отмены оно перейдет в статус «Отменен».
      </Text>
      <Buttons>
        <NewButton
          dataTestId="cancel-appeal-button"
          label="Да, отменить"
          onClick={onConfirm}
          isPending={isPending}
        />
        <NewButton
          dataTestId="close-appeal-modal-button"
          label="Назад"
          buttonType="grey"
          onClick={onCancel}
        />
      </Buttons>
    </Wrapper>
  </ModalLayoutV2>
)

const Wrapper = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: ${palette.white};
  max-width: 450px;
  width: 450px;
`

const Text = styled.div`
  ${TextLLight}
`

const Buttons = styled.div`
  display: flex;
  gap: 16px;
`

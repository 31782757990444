import { attach, combine } from 'effector'
import { createForm } from 'effector-forms'

import {
  AdditionalRequestModalInfo,
  ResponseDialogRead,
  getMessageListReqFx,
  markAdditionalRequestAnswerAsReadReqFx,
  postMessageReqFx,
  putMessageReqFx,
} from '@/dal'
import { d } from './domain'

export const $additionalModalInfo = d.store<AdditionalRequestModalInfo & {
  isAgreedContractor: boolean
} | null>(null)
export const closeModal = d.event<void>()

export const $requestInfo = d.store<ResponseDialogRead | null>(null)
export const $messageList = $requestInfo.map((info) => info?.messages ?? null)
export const $metaInfo = $requestInfo.map((info) => info?.metadata ?? null)

export const additionalForm = createForm({
  domain: d,
  fields: {
    files: {
      init: [] as File[],
    },
    message: {
      init: '',
    },
  },
})

export const postMessageFx = attach({
  effect: postMessageReqFx,
})

export const putMessageFx = attach({
  effect: putMessageReqFx,
})

export const $isPendingCreateMessage = combine(
  [postMessageFx.pending, putMessageFx.pending],
  ([a, b]) => a || b,
)

export const getRequestInfoFx = attach({
  effect: getMessageListReqFx,
})

export const markAdditionalRequestAnswerAsReadFx = attach({
  effect: markAdditionalRequestAnswerAsReadReqFx,
})

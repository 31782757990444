import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { ApprovementProcessItem } from '@/dal'
import { $previousRoute, RouterPaths } from '@/features/app/model'
import { getFullTime } from '@/lib/date'

import { palette } from '../../palette'
import { HeadL, TextMLight } from '../../typography'
import { Icon2 } from '../../icons'
import { SectionTabs } from '../Tabs'
import { DocumentHeaderButtons } from './DocumentHeaderButtons'
import { ButtonsProps } from './types'
import { ApprovementProcessButton } from './ApprovementProcessButton'

type Tab = { id: any, label: string }

type Props = ButtonsProps & {
  link: RouterPaths,
  documentName: string,
  lastUpdate: string // isoDate
  downloadInstructionURL?: string | null
  downloadInstructionText?: string
  isBlocked?: boolean
  unlockDate?: string | null // isoDate
  canCopy?: boolean
  tabs?: Tab[]
  activeTabId?: Tab['id']
  docSubTypeSlot?: React.ReactNode | null
  onChangeTab?: (id: Tab['id']) => void
  invalidTabId?: Tab['id']
  approvementProcess?: ApprovementProcessItem[] | null
}

export const DocumentHeader = ({
  link,
  documentName,
  lastUpdate,
  downloadInstructionURL,
  activeTabId,
  tabs,
  docSubTypeSlot,
  onChangeTab,
  invalidTabId,
  downloadInstructionText = 'Инструкция по использованию',
  approvementProcess,
  ...buttonsProps
}: Props) => {
  const history = useHistory()
  const previousRoute = useUnit($previousRoute)

  const handleGoBack = (e: React.MouseEvent) => {
    if (previousRoute) {
      history.goBack()
      e.preventDefault()
    }
  }

  return (
    <Wrapper>
      <Row>
        <Col>
          <TitleWrapper>
            <GoBack to={link} onClick={handleGoBack}>
              <Icon2
                icon="arrowLeft"
                size={24}
                color="grey90"
              />
              <Title>
                {documentName}
              </Title>
            </GoBack>

            {docSubTypeSlot}
          </TitleWrapper>

          <Subtitle>
            <LastUpdate>
              Последнее изменение
              {' '}
              {getFullTime(lastUpdate)}
            </LastUpdate>
            {downloadInstructionURL && (
              <>
                <span>·</span>
                <InstructionWrapper href={downloadInstructionURL} download>
                  <span>{downloadInstructionText}</span>
                  <Icon2 icon="document" color="inherit" />
                </InstructionWrapper>
              </>
            )}
          </Subtitle>

        </Col>

        <RightRow>
          {approvementProcess && Boolean(approvementProcess.length) && (
            <ApprovementProcessButton approvementProcess={approvementProcess} />
          )}
          <DocumentHeaderButtons {...buttonsProps} />
        </RightRow>
      </Row>

      {tabs?.length && onChangeTab && (
        <SectionTabs
          items={tabs}
          activeId={activeTabId}
          onItemClick={onChangeTab}
          inValidTabId={invalidTabId}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${palette.white};
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px;
  border-radius: 20px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

const RightRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const GoBack = styled(Link)`
  display: flex;
  gap: 6px;
`

const Col = styled.div`
`

const Title = styled.h1`
  ${HeadL}
`

const Subtitle = styled.div`
  ${TextMLight}
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  color: ${palette.grey70};
  margin-left: 30px;
`

const LastUpdate = styled.p`
`

const InstructionWrapper = styled.a`
  ${TextMLight}
  display: flex;
  gap: 2px;
  align-items: center;
  color: ${palette.accent100};
`

import { attach } from 'effector'

import {
  ContractItem,
  DocumentAlias,
  copyActReqFx,
  copyKorDocReqFx,
  copyPaymentReqFx,
  copyPlanReqFx,
  deleteActReqFx,
  deleteEstimateCorrectionReqFx,
  deletePaymentReqFx,
  deletePlanReqFx,
} from '@/dal'

import { d } from './domain'
import {
  CreateDocumentInfo,
  DocumentManagerActions,
  OnCopyDocument,
  TargetDocument,
} from './types'

export const $isModalOpen = d.store(false)
export const $isModalWithSelect = d.store(false)
export const closeCreateDocumentModal = d.event<void>()

export const $selectedDocumentType = d.store<DocumentAlias | null>(null)
export const selectType = d.event<DocumentAlias>()

export const $targetDocument = d.store<TargetDocument|null>(null)
export const $action = d.store<DocumentManagerActions | null>(null)

export const $createDocumentInfo = d.store<CreateDocumentInfo | null>(null)

export const $contractInfo = d.store<ContractItem | null>(null)
export const $contractFeatures = $contractInfo.map((info) => info?.features ?? null)

export const onRejectConfirm = d.event<void>()
export const onConfirm = d.event<void>()

export const resetStores = d.event<void>()

export const deleteDocumentFx = d.effect<Pick<TargetDocument, 'id' | 'type' | 'documentName'>, string, Error>()
export const copyDocumentFx = d.effect<Pick<TargetDocument, 'id' | 'type'>, OnCopyDocument, Error>()

export const $isDeletingDocuments = d.store<{
  id: TargetDocument['id'],
  type: TargetDocument['type'],
}[]>([])

export const $isCopyingDocuments = d.store<{
  id: TargetDocument['id'],
  type: TargetDocument['type'],
}[]>([])

export const copyActFx = attach({
  effect: copyActReqFx,
})

export const copyPaymentFx = attach({
  effect: copyPaymentReqFx,
})

export const copyPlanFx = attach({
  effect: copyPlanReqFx,
})

export const copyEstimateFx = attach({
  effect: copyKorDocReqFx,
})

export const deleteActFx = attach({
  effect: deleteActReqFx,
})

export const deletePaymentFx = attach({
  effect: deletePaymentReqFx,
})

export const deletePlanFx = attach({
  effect: deletePlanReqFx,
})

export const deleteEstimateCorrectionFx = attach({
  effect: deleteEstimateCorrectionReqFx,
})

import React, { useCallback } from 'react'
import styled from 'styled-components'

import { DocumentAlias, DocumentStatuses } from '@/dal'
import { DefaultButton, SelectItemButton } from '@/ui'
import { noop } from '@/lib/noop'

import { getDocumentFiles } from '../../model/private'
import { DocumentFilesModal } from '../containers'

type Props = {
  id: number
  docType: DocumentAlias
  buttonLabel?: string
  downloadLink?: string
  status?: DocumentStatuses
  onClick?: () => void
}

export const DocumentFilesButton = React.memo(({
  id,
  docType,
  buttonLabel = 'Документы для согласования',
  downloadLink,
  status,
  onClick,
}: Props) => {
  const isDraft = status === DocumentStatuses.Draft

  const Wrapper = useCallback(({ children }) => {
    // TODO поменять условия
    if (isDraft || downloadLink) {
      return (
        <a href={downloadLink} download>
          {children}
        </a>
      )
    }
    if (isDraft || onClick) {
      return (
        <Button onClick={onClick}>
          {children}
        </Button>
      )
    }
    return <>{children}</>
  }, [status, downloadLink])

  return (
    <Wrapper>
      <SelectItemButton
        text={buttonLabel}
        icon="download"
        buttonStyle="secondary"
        hasBorder={false}
        dataTestId="open-doc-files-modal-btn"
        // TODO временно убираем открытие модалки
        // onClick={!isDraft ? () => getDocumentFiles({ id, docType }) : noop}
      />
      <DocumentFilesModal />
    </Wrapper>
  )
})

const Button = styled(DefaultButton)`
  width: 100%;
`

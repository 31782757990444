import React from 'react'
import styled, { css } from 'styled-components'

import { TextMLight } from '../../typography'
import { palette } from '../../palette'
import { CellInputWrapper } from './CellInputWrapper'

type Props = {
  value: string
  textAlign?: 'right' | 'center' | 'left'
  placeholder?: string
  height?: number
  maxLength?: number
  disabled?: boolean
  dataTestId: string
  autoGrow?: boolean
  isInvalid?: boolean
  maxHeight?: number,
  maxLines?: number
  popupWidth?: number
  onChange: (val: string) => void
  onToggle?: (val: boolean) => void
}

export const CellTextArea = ({
  onChange,
  onToggle,
  dataTestId,
  placeholder,
  textAlign = 'left',
  value,
  height,
  maxLength,
  disabled,
  autoGrow,
  isInvalid,
  maxHeight,
  maxLines,
  popupWidth,
}: Props) => {
  const [autoHeight, setHeight] = React.useState<number|null>(height ?? null)
  const [visible, setVisible] = React.useState<boolean>(false)
  const inputRef = React.useRef<HTMLTextAreaElement>(null)

  const toggleVisibilityHandler = (val: boolean) => {
    if (onToggle) onToggle(val)
    setVisible(val)
  }

  const calcHeight = () => {
    if (!autoGrow || !inputRef.current) return
    const baseHeight = (height || 0)
    const newHeight = inputRef.current.scrollHeight > baseHeight
      ? inputRef.current.scrollHeight
      : baseHeight
    if (maxHeight) {
      setHeight(newHeight > maxHeight ? maxHeight : newHeight)
    } else {
      setHeight(newHeight)
    }
  }

  React.useLayoutEffect(() => {
    calcHeight()
  }, [value, visible])

  React.useEffect(() => {
    if (!value) return setHeight(height ?? null)
  }, [value])

  return (
    <CellInputWrapper
      value={value || placeholder || ''}
      inputRef={inputRef}
      isInvalid={isInvalid}
      onToggle={toggleVisibilityHandler}
      maxLines={maxLines}
      popupWidth={popupWidth}
      padding="4px"
    >
      <TextArea
        data-testid={dataTestId}
        disabled={disabled}
        maxLength={maxLength}
        height={autoGrow ? autoHeight : height}
        value={value}
        placeholder={placeholder}
        textAlign={textAlign}
        onChange={(e) => {
          onChange(e.target.value)
        }}
        isInvalid={Boolean(isInvalid)}
      />
    </CellInputWrapper>
  )
}

const TextArea = styled.textarea<{
  isInvalid: boolean,
  height?: number | null,
  textAlign: 'right' | 'center' | 'left'
}>`
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  width: 100%;
  ${TextMLight}
  resize: none;
  outline: none;
  transition: color 0.15s linear;
  border: none;
  text-align: ${({ textAlign }) => textAlign};

  &:not(:disabled)::placeholder {
    color: ${palette.grey60};
  }

  &:disabled {
    color: ${palette.grey70};
  }

  ${({ isInvalid }) => isInvalid && css`
    color: ${palette.red100};

    &::placeholder {
      color: ${palette.red100};
    }
  `}
`

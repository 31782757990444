import { attachWrapper } from '@42px/effector-extra'
import { AxiosError, AxiosResponse } from 'axios'

import { Method, authRequestFx } from '../request'
import {
  GetWorkListParams,
  GetGroupTreeParams,
  Group,
  TreeItem,
} from './types'
import { AbortableRequest } from '../interfaces'

export const getWorkTreeReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, type }: GetWorkListParams) => ({
    method: Method.get,
    url: `/groups/${type}/${id}/`,
  }),
  mapResult: ({ result }: { result: AxiosResponse<Group[]> }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => error.response?.data,
})

export const getGroupsTreeReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ documentId, documentType, signal }: AbortableRequest<GetGroupTreeParams>) => ({
    method: Method.get,
    url: `/${documentType}/groups/${documentId}/`,
    signal,
  }),
  mapResult: ({ result }: { result: AxiosResponse<TreeItem[]> }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => error.response?.data,
})

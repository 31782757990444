import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { ContractDirection } from '@/dal'
import { palette } from '@/ui'
import { numberWithSpaces } from '@/lib/number-represent'

import {
  Cell, DiffCell, TableRow, TableWrapper,
} from '../parts'
import { $documentInfo, $isDraft } from '../../../model'

export const ActSums = React.memo(() => {
  const info = useUnit($documentInfo)
  const isDraft = useUnit($isDraft)

  if (!info?.ks2 || !info?.ks3) return null

  const isPIR = info.act_type === ContractDirection.PDRD

  const { ks2, ks3 } = info
  const approveColor = isDraft ? 'grey70' : 'grey90'

  return (
    <Wrapper>
      <Container>
        <TableWrapper>
          <TableRow type="header">
            <Cell color="grey90" isBold isUppercase isFill>
              Сумма
            </Cell>
            <Cell>Заявлено, руб</Cell>
            <Cell>Принято, руб</Cell>
          </TableRow>

          <TableRow>
            <Cell isFill isUppercase>
              {isPIR ? 'Акт' : 'Данные КС-2'}
            </Cell>
            <Cell color="grey90">{numberWithSpaces(ks2.total.declared)}</Cell>
            <Cell>
              <Cell color={approveColor}>{numberWithSpaces(ks2.total.approved)}</Cell>
              {ks2.total.diff && (
              <DiffCell>{ks2.total.diff}</DiffCell>
              )}
            </Cell>
          </TableRow>

          <TableRow>
            <Cell isFill isUppercase>
              {isPIR ? 'Удержания' : 'Данные КС-3'}
            </Cell>
            <Cell color="grey90">{numberWithSpaces(ks3.total.declared)}</Cell>
            <Cell>
              <Cell color={approveColor}>{numberWithSpaces(ks3.total.approved)}</Cell>
              {ks3.total.diff && (
              <DiffCell>{ks3.total.diff}</DiffCell>
              )}
            </Cell>
          </TableRow>
        </TableWrapper>
      </Container>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  border-radius: 20px;
  overflow: hidden;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px 20px;
  background-color: ${palette.white};
`

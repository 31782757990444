import React from 'react'
import styled from 'styled-components'

import {
  NewTextButton,
  NotificationItem,
  NotificationType,
} from '@/ui'

import { onShowContragentRequest } from '../../../model/edit-private'

export const ContragentRequestNotification = () => {
  return (
    <NotificationItem
      body={(
        <NotificationContent>
          Отправлен запрос на создание нового контрагента в системе. Ожидайте ответа на почту.
          <NewTextButton
            dataTestId="open-request-modal"
            label="Данные контрагента"
            onClick={onShowContragentRequest}
            textStyle="M"
          />
        </NotificationContent>
      )}
      type={NotificationType.Info}
    />
  )
}

const NotificationContent = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`

import React from 'react'
import styled, { css } from 'styled-components'

import { GuaranteeContract } from '@/dal'
import {
  Icon2,
  LightTooltip,
  NewTextButton,
  TextMLight,
  palette,
} from '@/ui'
import { formatDateString } from '@/lib/date'
import { numberWithSpaces } from '@/lib/number-represent'
import { useTooltip } from '@/lib/hooks'
import { RouterPaths } from '@/features/app/model'

type Props = GuaranteeContract

export const GuaranteeContractItem = ({
  id,
  period,
  sum_of_security,
  name,
  is_period_expired,
}: Props) => {
  const {
    floatingStyles,
    getFloatingProps,
    getReferenceProps,
    isOpen,
    refs,
    arrowProps,
  } = useTooltip({
    placement: 'top-start',
    tooltipCrossOffset: -12,
  })

  return (
    <>
      <Wrapper>
        <ContractWrapper>
          <IconWrapper
            ref={refs.setReference}
            {...getReferenceProps()}
          >
            <Icon2
              icon={is_period_expired ? 'cross' : 'checkboxMark'}
              color={is_period_expired ? 'red100' : 'lightGreen100'}
            />
          </IconWrapper>
          <NewTextButton
            label={name}
            url={`${RouterPaths.Administrative}/${id}`}
            textStyle="ML"
            dataTestId=""
            isGrey={is_period_expired}
            hasUnderline
            isNewBlank
          />
        </ContractWrapper>

        <TextWrapper isGrey={is_period_expired}>
          {formatDateString(period.date_start)}
          {' - '}
          {formatDateString(period.date_end)}
        </TextWrapper>

        <TextWrapper isGrey={is_period_expired}>
          {sum_of_security && numberWithSpaces(sum_of_security)}
        </TextWrapper>
      </Wrapper>

      <LightTooltip
        floatingStyles={floatingStyles}
        isOpen={isOpen}
        refs={refs}
        getFloatingProps={getFloatingProps}
        size="M"
        arrowProps={arrowProps}
      >
        {is_period_expired ? 'Срок обеспечения истёк' : 'Срок обеспечения еще не истёк'}
      </LightTooltip>
    </>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 400px) minmax(0, 160px) minmax(0, 120px);
  gap: 22px;
`

const ContractWrapper = styled.div`
  display: flex;
  gap: 4px;
`

const IconWrapper = styled.div`
  height: fit-content;
`

const TextWrapper = styled.p<{ isGrey?: boolean }>`
  ${TextMLight}
  text-align: right;

  ${({ isGrey }) => isGrey && css`
    color: ${palette.grey70};
  `}
`

import React from 'react'
import { useUnit } from 'effector-react'
import styled from 'styled-components'

import { DocumentAlias } from '@/dal'
import { SelectItemButton } from '@/ui'
import { createAttachmentsModalView } from '@/features/factories/attach-modal'
import { DocumentFilesButton } from '@/features/document-files'

import {
  $canSendFiles,
  $contract,
  attachmentsModel,
  openAttachmentsModal,
} from '../../../model/private'

const $files = $contract.map((contract) => (contract ? {
  attachments: contract.attachments,
  documentFiles: contract.contract_documents,
} : null))

const AttachmentsModal = createAttachmentsModalView(attachmentsModel)

export const ContractAttachments = React.memo(() => {
  const info = useUnit($contract)
  const files = useUnit($files)
  const canSendFiles = useUnit($canSendFiles)

  if (!info || !files) return null

  const {
    attachments,
    documentFiles,
  } = files

  return (
    <Wrapper>
      <SelectItemButton
        text="Мои вложения"
        additionalText={String(attachments?.length || 0)}
        icon={canSendFiles ? 'plusWithCircle' : 'arrowRightM'}
        buttonStyle={canSendFiles ? 'secondary' : 'primary'}
        hasBorder={false}
        dataTestId="open-attachments"
        onClick={openAttachmentsModal}
      />
      {documentFiles && (
        <DocumentFilesButton
          id={info.id}
          docType={DocumentAlias.CONTRACT}
          buttonLabel="Договор"
          downloadLink={documentFiles}
          status={info.status}
        />
      )}
      <AttachmentsModal />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  
  & > * {
    flex-grow: 1;
    width: 100%;
  }
`

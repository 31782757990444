import React from 'react'
import styled, { css } from 'styled-components'

import { ClosurePercentageStatus, ContractDirection, ContractItem } from '@/dal'

import { formatDateString } from '@/lib/date'
import { numberWithSpaces } from '@/lib/number-represent'
import { CloseButton, GetRetention, WorkCompleted } from '@/features/administrative/share'
import { newGetDocumentActionByAlias } from '@/lib/documentResolvers'
import { textHighlight } from '@/lib/text-highlight'
import {
  DocumentStatusLabel,
  NewIconButton,
  TextMLight,
  palette,
} from '@/ui'

import { ContractIcon } from './ContractIcon'
import { onClickActionButton, toggleOpenContract } from '../../model/private'
import { TableTemplate } from './TableTemplate'
import { Progress } from './Progress'

type Props = {
  contract: ContractItem | Omit<ContractItem, 'child'>
  searchValue: string
  isOpen: boolean
  isSubItem: boolean
  isClosing: boolean
  isRetentionPending: boolean
  isClosureUpdating: boolean
  isClosureUpdateDisabled: boolean
  onCloseButton: (params: { id: ContractItem['id'], needDocs: boolean }) => void
  onGetRetention: (id: ContractItem['id']) => void
  onUpdateClosure: () => void
}

export const TopRow = ({
  contract,
  searchValue,
  isOpen,
  isSubItem,
  isClosing,
  isRetentionPending,
  isClosureUpdating,
  isClosureUpdateDisabled,
  onCloseButton,
  onGetRetention,
  onUpdateClosure,
}: Props) => {
  const hasChildren = Boolean((contract as ContractItem).child)

  const actionInfo = React.useMemo(() => (
    newGetDocumentActionByAlias(contract.action)
  ), [contract.action])

  const preventClick = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const openContractChildren = (e: React.MouseEvent) => {
    if (!hasChildren) return
    preventClick(e)
    toggleOpenContract(contract.id)
  }

  const handleActionButtonClick = (e: React.MouseEvent) => {
    preventClick(e)
    if (!contract.action) return
    onClickActionButton({
      action: contract.action,
      id: contract.id,
      status: contract.status,
    })
  }

  const handleUpdateClosure = (e: React.MouseEvent) => {
    preventClick(e)
    onUpdateClosure()
  }

  const {
    progress,
    status,
    docs_link,
    has_closing_documents,
    closure_data_update_at,
  } = contract.closure_percentage

  const {
    date,
    date_end,
    guaranteed_date,
    sum,
  } = contract.additional_data

  const { is_advance_possible } = contract.features

  const highlightedText = searchValue
    ? textHighlight(
      contract.name,
      searchValue,
      palette.yellow30,
      'inherit',
    )
    : contract.name

  const handleButton = (flag: boolean) => {
    onCloseButton({ id: contract.id, needDocs: flag })
  }

  const hasClosurePercent = ![
    ContractDirection.BANK_WARRANTY,
    ContractDirection.CONTRACT_GUARANTEE,
  ].includes(contract.direction)

  const isHidden = status === ClosurePercentageStatus.Hidden
  const showProgress = status === ClosurePercentageStatus.InProgress
  const showCloseButton = status === ClosurePercentageStatus.CanCloseContract
  const showWorkCompleted = status === ClosurePercentageStatus.WorkCompleted
  const swowGetRetention = status === ClosurePercentageStatus.CanGetGuaranteeRetention

  return (
    <Wrapper>

      <Cell>
        <Row isOpen={Boolean(isOpen)} isSubItem={isSubItem}>
          <IconWrapper onClick={openContractChildren}>
            <ContractIcon
              hasChildren={hasChildren}
              type={contract.type.map_id}
              isOpen={isOpen}
              isSubItem={isSubItem}
            />
          </IconWrapper>

          <Col>
            <MainText className="contract__name">
              {highlightedText}
            </MainText>
            <SubText>
              {contract.project.name}
            </SubText>
          </Col>
        </Row>
      </Cell>

      <Cell topPadding={8}>
        {actionInfo && (
          <NewIconButton
            icon={actionInfo.icon}
            size={16}
            color="accent100"
            padding="8px"
            dataTestId={`action-${contract.action}-${contract.id}`}
            tooltipText={actionInfo?.text}
            hoverBackgroundColor="accent10"
            onClick={handleActionButtonClick}
            borderRadius="50%"
          />
        )}
      </Cell>

      <Cell>
        <MainText>
          {' '}
          {
            (date && date_end) ? `${formatDateString(date)} – ${formatDateString(date_end)}`
              : '-'
          }
        </MainText>
      </Cell>

      <Cell>
        <MainText>{contract.company_fsk}</MainText>
      </Cell>

      <Cell>
        <MainText>
          {numberWithSpaces(
            ['bank_warranty', 'contract_guarantee'].includes(contract.direction)
              ? contract.sum_of_security || undefined
              : contract.additional_data.sum || undefined
          )}
        </MainText>
      </Cell>

      <Cell>
        <DocumentStatusLabel
          status={contract.status}
          progress={contract.responsible_progress}
          progressPosition="right"
        />
      </Cell>

      {hasClosurePercent && (
        <Cell topPadding={6}>

          {showProgress && (
            <Progress
              progress={progress}
              hasAdvance={is_advance_possible}
              closureUpdateDate={closure_data_update_at}
              isClosureUpdating={isClosureUpdating}
              isClosureUpdateDisabled={isClosureUpdateDisabled}
              onUpdateClosure={handleUpdateClosure}
            />
          )}

          {showCloseButton && (
            <CloseButton
              type="line-primary"
              onButton={handleButton}
              isPending={isClosing}
              contractId={contract.id}
            />
          )}

          {showWorkCompleted && (
            <WorkCompleted
              hasDocs={has_closing_documents}
              link={docs_link}
            />
          )}

          {swowGetRetention && (
            <GetRetention
              contractId={contract.id}
              hasDocs={has_closing_documents}
              link={docs_link}
              onButton={() => onGetRetention(contract.id)}
              isPending={isRetentionPending}
            />
          )}

          {!showProgress && !isHidden && (
            <InfoWrapper>
              {guaranteed_date && (
                <InfoRow>
                  <Label>Гарантийный срок</Label>
                  <Value>{guaranteed_date}</Value>
                </InfoRow>
              )}
              {sum && (
                <InfoRow>
                  <Label>Сумма удержания</Label>
                  <Value>{numberWithSpaces(sum)}</Value>
                </InfoRow>
              )}
            </InfoWrapper>
          )}

        </Cell>
      )}

    </Wrapper>
  )
}

type RowProps = {
  isOpen: boolean,
  isSubItem?: boolean
}

const InfoWrapper = styled.div`
  width: 100%;
  margin-top: 12px;
`

const InfoRow = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  ${TextMLight}
`

const Label = styled.div`
  color: ${palette.grey70};
`

const Value = styled.div``


const Row = styled.div<RowProps>`
  display: flex;
  gap: 6px;
  transition: color 0.2s linear;
  color: inherit;

  ${({ isSubItem }) => isSubItem && css`
    padding-left: 22px;
  `};
`

const IconWrapper = styled.div`
  min-width: 16px;
  max-width: 16px;
  ${({ onClick }) => onClick && css`
    cursor: pointer;
  `}
`

const Wrapper = styled.div`
  ${TableTemplate}
  width: 100%;
`

type CellProps = {
  topPadding?: number
}

const Cell = styled.div<CellProps>`
  padding: ${({ topPadding }) => (topPadding || 12)}px 12px 8px;
  overflow: hidden;

  &:first-child {
    padding-left: 16px;
  }

`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const MainText = styled.div`
  ${TextMLight}
`

const SubText = styled.div`
  ${TextMLight}
  color: ${palette.grey70};
`

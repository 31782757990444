import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import {
  NewTextButton,
  NotificationItem,
  NotificationType,
  WINDOW_WIDTH,
} from '@/ui'

import {
  $deletingPaymentOrders,
  onDeleteSinglePaymentOrder,
  onOpenPaymentOrdersModal,
} from '../../../model/payment-orders.private'
import { PaymentOrderItem } from '../parts'
import {
  $canDeleteLastPaymentOrder, $canEditPaymentOrders, $documentInfo,
} from '../../../model'
import { $isActionPending } from '../../../model/header.private'

export const PaymentOrders = React.memo(() => {
  const [info, canEdit, canDeleteLast, deletingOrders, isActionPending] = useUnit([
    $documentInfo,
    $canEditPaymentOrders,
    $canDeleteLastPaymentOrder,
    $deletingPaymentOrders,
    $isActionPending,
  ])

  if (!info) return null

  const hasPaymentOrders = Boolean(info.payment_orders?.length)

  if (!hasPaymentOrders && !canEdit) return null

  const { payment_orders } = info

  const canDelete = canEdit && ((payment_orders?.length ?? 0) > 1 || canDeleteLast)

  return (
    <Wrapper>
      {hasPaymentOrders && (
        <PaymentList>
          {payment_orders?.map(({ number, id, date }) => (
            <PaymentOrderItem
              key={id}
              id={id}
              number={number}
              date={date}
              canDelete={canDelete}
              isLoading={deletingOrders.includes(id)}
              onDelete={onDeleteSinglePaymentOrder}
            />
          ))}
        </PaymentList>
      )}

      {canEdit && (
        <>
          <ButtonWrapper>
            <NewTextButton
              label="Внести данные платежных поручений"
              prefixIcon="plusButton"
              textStyle="L"
              iconSize={16}
              isDisabled={isActionPending}
              onClick={onOpenPaymentOrdersModal}
              dataTestId="add-payment-order-btn"
            />
          </ButtonWrapper>
          <NotificationItem
            type={NotificationType.Warning}
            body="Данные платежных поручений обязательны для заполнения, если сумма зачета аванса больше нуля.
              Платежные поручения используются для формирования счет-фактуры (раздел 5). Если данные будут указаны неверно,
              то в ДИАДОК придут некорректные документы. "
          />
        </>
      )}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const PaymentList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 6px 24px;

  @media (max-width: ${WINDOW_WIDTH.desktop - 1}) {
    display: flex;
    flex-direction: column;
  }
`

const ButtonWrapper = styled.div`
  padding: 7px 0;
`

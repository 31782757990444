import React from 'react'

import { IconName2, NewIconButton, PaletteColor } from '@/ui'

type Props = {
  icon: IconName2
  color?: PaletteColor
  isDisabled: boolean
  dataTestId: string
  onClick: () => void
}

export const ActionButton = ({
  icon, color, isDisabled, dataTestId, onClick,
}: Props) => {
  return (
    <NewIconButton
      icon={icon}
      size={16}
      color={color ?? 'grey60'}
      hoverColor="accent90"
      disabled={isDisabled}
      disabledColor="grey30"
      activeColor="accent80"
      dataTestId={dataTestId}
      onClick={onClick}
    />
  )
}

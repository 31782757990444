import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { DocumentStatuses } from '@/dal'
import { HeadXL, palette } from '@/ui'
import { setDocumentsFlowFilters } from '@/features/document-flow/filters/model'

import { SpecificDocumentCounter } from '../parts'
import { $headerInfo } from '../../model/private'

const activeTasksStatuses = [
  DocumentStatuses.AgreedTechSupervisor,
  DocumentStatuses.AgreedContractor,
  DocumentStatuses.ToBeAgreed,
]

export const DocumentFlowHeader = React.memo(() => {
  const info = useUnit($headerInfo)

  return (
    <Wrapper>
      <TitleWrapper>
        <p>
          Документооборот
        </p>
        {info?.all_documents_count && (
          <Count>
            {info.all_documents_count}
          </Count>
        )}
      </TitleWrapper>

      <DocCountsWrapper>
        {info && (
          <SpecificDocumentCounter
            text="На согласовании"
            count={info.active_tasks}
            onClick={() => setDocumentsFlowFilters({ status: activeTasksStatuses })}
          />
        )}

        {info?.approved_tasks && (
          <SpecificDocumentCounter
            text="Утверждено"
            count={info.approved_tasks}
            countColor="lightGreen100"
            onClick={() => setDocumentsFlowFilters({ status: [DocumentStatuses.Approved] })}
          />
        )}
      </DocCountsWrapper>
    </Wrapper>
  )
})

const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`

const TitleWrapper = styled.div`
  ${HeadXL}
  display: flex;
  gap: 12px;
`

const Count = styled.div`
  color: ${palette.grey70};
`

const DocCountsWrapper = styled.div`
  display: flex;
  gap: 8px;
`

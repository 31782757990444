import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import {
  CloseModalButton,
  DrawerWrapper,
  HeadXL,
  Loader,
  TextS,
  palette,
} from '@/ui'
import { useOnChangeLocation } from '@/lib/useOnChangeLocation'

import { MessageForm, MessageList } from '../containers'
import {
  $additionalModalInfo,
  $isPendingCreateMessage,
  $metaInfo,
  closeModal,
  getRequestInfoFx,
} from '../../model/private'


export const AdditionalModal = React.memo(() => {
  const isShow = useUnit($additionalModalInfo)
  const metadata = useUnit($metaInfo)
  const isPendingInfo = useUnit(getRequestInfoFx.pending)
  const isPendingCreateMessage = useUnit($isPendingCreateMessage)

  useOnChangeLocation(closeModal)

  if (!isShow) return null

  return (
    <DrawerWrapper
      closeModal={closeModal}
    >
      <Header>
        <Title>
          Чат по доп. запросам
        </Title>
        <SubTitle>
          {metadata?.breadchrumbs.reduce((val, { document_name }) => (
            val ? `${val} / ${document_name}` : document_name
          ), '')}
        </SubTitle>
        <CloseModalButton
          dataTestId="close-additional-modal"
          onClick={closeModal}
        />
      </Header>
      <ListWrapper>
        <MessageList />
      </ListWrapper>
      {metadata?.answer_required && (<MessageForm />)}
      {(isPendingInfo || isPendingCreateMessage) && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
    </DrawerWrapper>
  )
})

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0.5;
  background-color: ${palette.white};
`

const Header = styled.div`
  padding: 32px 24px 20px 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Title = styled.div`
  ${HeadXL}
`

const SubTitle = styled.div`
  ${TextS}
  color: ${palette.grey60};
`

const ListWrapper = styled.div`
  flex-grow: 1;
  width: 100%;
`

import { AxiosResponse } from 'axios'

export type DocumentFeatures = {
  can_delete?: boolean
  can_cancel_approving?: boolean
  can_approve?: boolean
  can_copy?: boolean
  can_send_to_approving?: boolean
  is_answer_required?: boolean
  is_special_visa?: boolean
  can_create_visa?: boolean
  can_reset?: boolean
  can_create_item?: boolean
  can_fill_by_remaining?: boolean
  can_edit_tablepart?: boolean
  can_edit?: boolean
  can_open_visa_chat?: boolean
  is_second_approving?: boolean
  can_reject_approving?: boolean
  on_approving?: {
    id: number
    name: string
    type: DocumentAlias,
  } | null
  can_add_attachments?: boolean
  has_unread_answer?: boolean // +1 ответ на доп. запрос
}

export type DocumentProgress = {
  total_visas_default: number
  agreed_by_default: number
  total_additional_visas: number
  agreed_additional_visas: number
  visa: string | null
  responsible: string | null
  responsible_mail: string | null
  deadline: string | null
  common_responsible?: string
  messages_count?: number // TODO возможно в рамках переделки Выполнений
}

export type FileInfo = {
  file: string
  name: string
}

export enum DocumentActions {
  answer_on_additional_request = 'answer_on_additional_request',
  sign_documents = 'sign_documents',
  accept_solution = 'accept_solution'
}

export type Response<T> = {
  result: AxiosResponse<T>
}

export type AbortableRequest<T = void> = T & {
  signal?: AbortSignal
}

export type PaginationType<T> = {
  data: T
  total: number
}

export type WorkItem = {
  count: string
  saldo: string
  count_in_estimate: string
  act: number
  cost_all: string
  cost_material: string
  cost_work: string
  cost_material_approved: string
  cost_work_approved: string
  work: Work
  reason_rejection: string
  cost_all_approved: string
  count_approved: string
  count_declared: string
  is_changeble: boolean
}

export type Work = {
  id: number
  work_type: string
  unit: string
  cost_work: string
  cost_material: string
  cost_all: string
  code: string
  code_1c: string
  count: string
  group: number
  name: string
  price_all: string
  price_equipment: string
  price_material: string
  price_work: string
  note: string | null
  building: {
    code_1c: string
    name: string
  }
  article_outlay?: {
    code_1c: string
    id: number
    name: string
  }
}
// @TODO позже надо будет прибраться
export enum SystemAlias {
  Home = 'Home',
  Payments = 'Payments',
  ActConstructor = 'ActConstructor',
  FundingSchedule = 'FundingSchedule',
  DocumentFlow = 'DocumentFlow',
  Administrative = 'Administrative',
  Help = 'Help',
  EstimateCorrection = 'EstimateCorrection',
  CreateEstimate = 'CreateEstimate',
  Compensation = 'Compensation',
}

export type SystemVisibility = {
  name: string
  alias: SystemAlias
}

export type User = {
  email: string
  phone: string
  first_name: string
  last_name: string
  patronymic: string
  company: CompanyItem[]
  position: string
  site_section_visibility: SystemVisibility[]
  avatar: string
  is_read_only_access: boolean
  act_other_deductions_types?: {
    id: number
    name: string
  }[]
}

export type SolutionItem = {
  date: string // // YYYY-MM-DD,
  status: string // "Решение принято",
  type_solution: string // "Согласовано"
}

export type CompanyItem = {
  id: number
  name: string
  full_name: string | null
  inn: string | null
  kpp: string | null
  okpo: string | null
  bank_bik: string | null
  bank_name: string | null
  acc_number: string | null
  phone_number: string | null
  sign_position: string | null
  sign_name: string | null
  legal_address: string | null
  actual_address: string | null
  postal_address: string | null
  solution: SolutionItem | null
  count_days_until_completion: number
  approved: boolean
}

export type Contract = {
  id: number
  name: string
  company: Contragent
  company_fsk: {
    id: number
    uuid_1c: string
    name: string
    code_1c: string
  }
  project: {
    id: number
    name: string
    code_1c: string
  }
  code_1c: string
  number_1c: string
  date: string // YYYY-MM-DD
  status: DocumentStatuses
  guaranteed_percentage: string
  avans_percentage: string
  archived: boolean
  subject: string
  available_files: boolean
  action: DocumentActions | null,
  document_actions: {
    [key in DocumentAlias]?: {
      action: DocumentActions,
      id: number
    }
  }
  icon: {
    act: number | null
    visa: boolean
  }
  building: {
    id: number
    name: string
  }[]
  child: Contract[] | null
  features: {
    act: {
      can_create: boolean
    }
  }
}

export type Estimate = {
  id: number
  date_add: string // YYYY-MM-DD
  number_1c: string
  cost_work: string
  cost_material: string
  cost_all: string
  company_fsk: number
  contragent: Contragent
  contract: Contract
  acts_in_estimate: {
    act: number
    date_add: string // YYYY-MM-DD
    date_start: string // YYYY-MM-DD
    date_stop: string // YYYY-MM-DD
    status: DocumentStatuses
    number_1c: string
    last_update: string // ISODate
    cost: number
    cost_approved: number
    additional_request?: boolean
  }[]
  acts_count: number
  balance: Balance
}

export type Balance = {
  cost_estimate: string
  cost_acts_closed: string
  cost_acts_in_approve: string
  cost_acts_in_draft: string
  etimate_balance: string
}

export type FinplanItem = {
  id: string
  status: number
  uuid_1c: string
  number_1c: string
  date_add: string // YYYY-MM-DD
  date_start: string // YYYY-MM-DD
  date_stop: string // YYYY-MM-DD
  last_update: string // ISODate
  last_sync: string
  cost_all: string
  cost_work: string
  cost_material: string
  cost_work_approved: string
  cost_material_approved: string
  cost_all_approved: string
  description_error: string
  detail_error: string
  md5: string
  // @TODO не используется
  estimate: number
  company: number
  contract: number
  construction_object: number
}

export type Contragent = {
  id: number
  name: string
  full_name: string
  inn: string
  kpp: string
  okpo: string
  bank_bik: string
  bank_name: string
  acc_number: string
  phone_number: string
  sign_position: string
  sign_name: string
  legal_address: string
  actual_address: string
  postal_address: string
  solution: SolutionItem
  approved: boolean
}

export type DropdownItem = {
  text: string
  value: string
}

export type DropdownState = DropdownItem[] | null

export enum DocumentStatuses {
  // Черновик
  Draft = 1,
  //  На согласовании технадзора
  AgreedTechSupervisor = 2,
  // На согласовании подрядчика
  AgreedContractor = 3,
  // На согласовании
  ToBeAgreed = 4,
  // Утвержден
  Approved = 5,
  // Подписан
  Signed = 6,
  // Отклонен
  Declined = 7,
  // Выполнен
  Completed = 8,
  // Закрыт
  Closed = 9,
  // Отправлен
  Sent = 10,
  // В работе
  WIP = 11,
  // Предоставить номера документов
  DocumentDemand = 12,
  // Подписать документы в ДИАДОК
  SignInDiadoc = 13,
  // Оплачен
  Payed = 14,
  // Согласована
  Accepted = 15,
  //  В оплате
  InPaying = 16,
  // Согласовано
  FinalApproved = 17,
  // на аннулировании
  OnCancellation = 18,
  // аннулирован
  Cancelled = 19,
  // Бюджет запланирован
  BudgetIsPlanned = 20,
  // Неактуален
  Irrelevant = 21,
  // Подписать АКТ в ДИАДОК
  SignActInDiadoc = 22,
  // На подписании у ЗАКАЗЧИКА
  SignInClient = 23,
  // Подписать СФ в ДИАДОК
  SignInSFDiadoc = 24,
  // Ожидание документов, временный статус
  DocumentsWaiting = 555,
  // Расторгнут
  ContractTerminated = 25,
  // На подписании
  OnSigned = 26,
}

export type FileItem = {
  file: File
  imgUrl?: string
  extension: string
}

export type ErrorResponse = {
  error: string
  text: string
}

export type SendCommentParams = {
  text: string,
  id: number,
  // Возможно это не нужно на клиенте
  reply_for?: number
}

export type CommentAuthor = {
  avatar: string
  first_name: string
  last_name: string
  patronymic: string
}

export type CommentItem = {
  id: string,
  target: number,
  author: CommentAuthor,
  text: string,
  replies: Omit<CommentItem, 'replies'>[]
  written_at: string
  reply_for: string
}

export type GetCommentsParams = {
  id: number,
  written_at?: '-written_at' | 'written_at'
}

export type CommentResponse = {
  results: CommentItem[],
  count: number,
  next: string | null
  previous: string | null
  total_pages: number
}

export type StatusResponse = {
  name: string
  map_id: number
}

export type SendReactionRequestParams = {
  targetId: number
  reaction: string
}

export type PaginationRequestParams = {
  limit?: number
  offset?: number
}

export enum DocumentAlias {
  ACT = 'act',
  BUDGET = 'budget',
  PAYMENT = 'payment',
  CONTRACT = 'contract',
  ESTIMATE = 'estimate',
  KOR_DOC = 'kor_doc',
  ADDITIONAL_AGREEMENT = 'additional_agreement',
  COMPENSATION_DOCUMENT = 'compensation_document',
}

export enum DocumentSubType {
  DOP = 'dop'
}

export type VisasDocType =
  DocumentAlias.ACT
  | DocumentAlias.CONTRACT
  | DocumentAlias.ESTIMATE
  | DocumentAlias.PAYMENT
  | DocumentAlias.BUDGET
  | DocumentAlias.KOR_DOC
  | DocumentAlias.COMPENSATION_DOCUMENT

export type RequestCancelError = {
  message: string
  name: string
}

export type DownloadDocumentFilePayload = {
  id: number
  type: DocumentAlias
}

export type FileDTO = {
  id: number | string
  name: string
  url: string
  extension: string
  can_delete?: boolean
  size?: string
}

export type DefaultUpdateFileParams = {
  files_to_delete?: FileDTO['id'][]
  files_to_save?: File[]
}

export type ExistingDoc = {
  id: number,
  name: string,
  status: DocumentStatuses
  is_signed_additional_agreement: boolean
} | null

export type CopyDocumentResponse<T> = {
  document: T,
  removed_draft_id: number | null
}

export enum ApprovementProcessStatus {
  SentToApproving = 'sent_to_approving',
  Approved = 'approved',
  Rejected = 'rejected',
}

export type ApprovementProcessItem = {
  from_status: DocumentStatuses
  action_did: ApprovementProcessStatus
  comment?: string
  date: string // ISODate
}

export type DocumentTracking = {
  text: string
  date: string | null // YYYY-MM-DD
}[]

export type RejectionReason = {
  author: string
  message: string
} | string | null

import React from 'react'

const escapeRegExp = (str: string) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

export const textHighlight = (
  text: string,
  textToHighlight: string,
  bgColor: string,
  textColor: string,
) => {
  const escapedText = escapeRegExp(textToHighlight)
  const regExp = escapedText.split(' ').join('|')

  const textHighlighted: (string | JSX.Element)[] = []
  const arr = [...text.matchAll(new RegExp(regExp, 'ig'))].map((el) => ({
    index: el.index ?? 0,
    content: el[0],
  }))
  let prevIndex = 0

  if (!arr.length) return text

  arr.forEach((el) => {
    const prevText = text.substring(prevIndex, el.index)
    textHighlighted.push(prevText)
    prevIndex = el.index + el.content.length
    textHighlighted.push(
      <span style={{ color: textColor, backgroundColor: bgColor }}>
        {el.content}
      </span>,
    )
  })
  if (prevIndex !== text.length) {
    textHighlighted.push(text.substring(prevIndex))
  }

  return <span>{textHighlighted}</span>
}

import React from 'react'
import { useUnit } from 'effector-react'

import { ContractDirection } from '@/dal'
import { TableHeaderCell } from '@/ui'
import { numberWithSpaces } from '@/lib/number-represent'

import { FirstHeaderCellWrapper, HeaderRowWrapper } from '../parts'
import {
  $hiddenCellGroup,
  $isFillByRemainingPending,
  $tableInfo,
  onFillByRemaining,
  toggleHiddenGroup,
} from '../../model/private'
import { $isDraft } from '../../../model'

type Props = {
  isScrolled?: boolean
}

export const TableHeaderPIR = React.memo(({ isScrolled }: Props) => {
  const [info, isHiddenGroup, isDataPending, isDraft] = useUnit([
    $tableInfo,
    $hiddenCellGroup,
    $isFillByRemainingPending,
    $isDraft,
  ])

  const canFillByRemaining = Boolean(info?.can_fill_by_remaining)

  return (
    <HeaderRowWrapper
      actType={ContractDirection.PDRD}
      hiddenFirstGroup={isHiddenGroup}
      isDraft={isDraft}
    >
      <FirstHeaderCellWrapper hasRightDivider={isHiddenGroup} hasShadow={isScrolled}>
        <TableHeaderCell
          label="Наименование услуг, работ и&nbsp;материалов"
          textAlign="left"
          isFirst
        />
      </FirstHeaderCellWrapper>

      {!isHiddenGroup && (
        <>
          <TableHeaderCell label="Примечание" isHiddenCell />
          <TableHeaderCell label="Статья оборотов" isHiddenCell />
        </>
      )}

      <TableHeaderCell
        label="Указано в&nbsp;смете"
        textAlign="right"
        groupNumber={1}
        isExpanded={!isHiddenGroup}
        expandButtonZIndex={isScrolled ? undefined : 10}
        onExpandButton={() => toggleHiddenGroup()}
      />

      <TableHeaderCell label="Остаток к&nbsp;выполнению" textAlign="right" />
      <TableHeaderCell label="Дата начала работ" textAlign="left" />
      <TableHeaderCell label="Дата окончания работ" textAlign="left" />

      <TableHeaderCell
        label="Сделано"
        info={`Количество выполненных работ – ${numberWithSpaces(info?.total_volume || '', { decimalScale: 0 })}`}
        checkboxLabel={canFillByRemaining ? 'Заполнить по остатку' : undefined}
        isDisableCheckbox={!info?.can_fill_by_remaining || isDataPending || !isDraft}
        isChecked={info?.fill_by_remaining}
        checkboxPosition="left"
        textAlign="left"
        onToggle={canFillByRemaining ? onFillByRemaining : undefined}
      />

      <TableHeaderCell label="Статус" textAlign="left" />
      <TableHeaderCell label="Ед. изм" />
      <TableHeaderCell label="Цена" textAlign="right" />

      <TableHeaderCell
        label="Стоимость за отчетный период"
        totalCost={info?.all_period_cost}
        textAlign="right"
      />
    </HeaderRowWrapper>
  )
})

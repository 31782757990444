import React from 'react'

import { PaletteColor } from '@/ui'
import { numberWithSpaces } from '@/lib/number-represent'

import { TableRow } from './TableRow'
import { DiffCell } from './DiffCell'
import { Cell } from './Cell'

type Props = {
  declared: string
  approved: string
  diff: string | null
  approveColor: PaletteColor
}

export const TotalCost = ({
  declared, approved, diff, approveColor,
}: Props) => {
  return (
    <TableRow type="summary">
      <Cell isFill isBold>Общая стоимость</Cell>
      <Cell color="grey90" isBold>{numberWithSpaces(declared)}</Cell>
      <Cell>
        <Cell color={approveColor} isBold>{numberWithSpaces(approved)}</Cell>
        {diff && (
          <DiffCell>{diff}</DiffCell>
        )}
      </Cell>
    </TableRow>
  )
}
